<template>
  <div class="PG_2">
    <img
      src="@/assets/presentacion/logoWhi.svg"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/logoDer.svg"
      class="animated fadeInRight logoD"
    />
    <div class="animated fadeInDown titulo">
      {{ institucion }}
    </div>
    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 2 - Continuar')"
    />
    <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 2 - Volver')"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      institucion: "",
    };
  },
  async mounted() {
    let user = firebase.auth().currentUser;
    var docRef = await db.collection("users").doc(user.uid).get();
    this.institucion = docRef.data().institucion;
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_2"),
    saludo: db.collection("presentacion").doc("bienvenida"),
  },
};
</script>

<style lang="scss">
.PG_2 {
  .logo {
    position: absolute;
    left: 126px;
    top: 62px;
    width: 207px;
    height: 134px;
  }
  .logoD {
    position: absolute;
    left: 1642px;
    top: 76px;
    width: 193px;
    height: 68px;
  }
  .titulo {
    position: absolute;
    left: 297px;
    top: 366px;
    width: 1324px;
    font-family: "VAG Rounded Std";
    font-size: 180px;
    font-weight: bold;
    line-height: 0.9;
    text-align: center !important;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
  }

  .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
