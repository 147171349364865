<template>
  <div id="container-presentacion">
    <div v-if="isAdmin" class="admin">
      <a href="/admin"> <el-button type="primary">ADMIN</el-button></a>
      <h4>PG_{{ currentPage }}</h4>
    </div>

    <div id="carcasa" class="reveal">
      <div class="lds-circle">
        <div class="loadingio-spinner-ripple-505tgqawfzb">
          <div class="ldio-152srjlg8i6">
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
      <div id="scene" class="slides" ref="slides">
        <!-- <template v-if="initializedReveal">
          <div v-for="(mouse, index) in mouses" :key="index">
            <div
              class="mouse"
              v-if="mouse.id != user.uid"
              :style="{ left: mouse.x + 'px', top: mouse.y + 'px' }"
            >
              <div
                class="cursor"
                :style="{ backgroundColor: mouse.color }"
              ></div>
              <h1>{{ mouse.nombre }}</h1>
            </div>
          </div>
        </template> -->
        <!-- - - - - - - - - - - - - - -  Hoja 01  - - - - - - - - - - - - - - -->
        <section
          class="PG_1"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_1.png')"
        >
          <PG1 @event="onEvent"></PG1>
        </section>
        <section
          class="PG_2"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_1.png')"
        >
          <PG2 @event="onEvent"></PG2>
        </section>
        <section
          class="PG_3"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_1.png')"
        >
          <PG3 @event="onEvent"></PG3>
        </section>
        <section
          class="PG_4"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_1.png')"
        >
          <PG4 @event="onEvent"></PG4>
        </section>
        <section
          class="PG_5"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_1.png')"
        >
          <PG5 @event="onEvent"></PG5>
        </section>
        <section
          class="PG_6"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_2.png')"
        >
          <PG6 ref="PG6" @event="onEvent"></PG6>
        </section>
        <section
          class="PG_7"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_2.png')"
        >
          <PG7 ref="PG7" @event="onEvent"></PG7>
        </section>

        <section
          class="PG_8"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_1.png')"
        >
          <PG8 @event="onEvent"></PG8>
        </section>
        <section
          class="PG_9"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_1.png')"
        >
          <PG9 @event="onEvent"></PG9>
        </section>
        <section
          class="PG_10"
          data-trasition="none"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_2.png')"
        >
          <PG10 @event="onEvent"></PG10>
        </section>
        <section
          class="PG_11"
          data-background-size="contain"
          data-background-video="https://desarrollo.factoriamedia.com/videos_tour/video_9_loop.mp4"
          :data-background-video-loop="true"
          :data-background-video-muted="true"
        >
          <PG11 @event="onEvent"></PG11>
        </section>
        <section
          class="PG_12"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_2.png')"
        >
          <PG12 @event="onEvent"></PG12>
        </section>

        <section
          class="PG_13"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_1.png')"
        >
          <PG13 @event="onEvent"></PG13>
        </section>
        <section
          class="PG_14"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_4.png')"
        >
          <PG14 @event="onEvent"></PG14>
        </section>
        <section
          class="PG_15"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_2.png')"
        >
          <PG15 @event="onEvent"></PG15>
        </section>
        <section
          class="PG_16"
          data-background-size="contain"
          data-background-video="https://desarrollo.factoriamedia.com/videos_tour/video_12_loop.mp4"
          :data-background-video-loop="true"
          :data-background-video-muted="true"
        >
          <PG16 @event="onEvent"></PG16>
        </section>
        <section
          class="PG_17"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_2.png')"
        >
          <PG17 @event="onEvent"></PG17>
        </section>
        <section
          class="PG_18"
          data-background-size="contain"
          data-background-video="https://desarrollo.factoriamedia.com/videos_tour/retornable.mp4"
          :data-background-video-loop="true"
          :data-background-video-muted="true"
        >
          <PG18 @event="onEvent"></PG18>
        </section>
        <section
          class="PG_19"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_2.png')"
        >
          <PG19 @event="onEvent"></PG19>
        </section>
        <section
          class="PG_20"
          data-background-size="contain"
          data-background-video="https://desarrollo.factoriamedia.com/videos_tour/no-retornable.mp4"
          :data-background-video-loop="true"
          :data-background-video-muted="true"
        >
          <PG20 @event="onEvent"></PG20>
        </section>
        <section
          class="PG_21"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_1.png')"
        >
          <PG21 @event="onEvent"></PG21>
        </section>

        <section
          class="PG_22"
          data-background-size="contain"
          data-background-video="https://desarrollo.factoriamedia.com/videos_tour/video_30_loop.m4v"
          :data-background-video-loop="true"
          :data-background-video-muted="true"
        >
          <PG22 @event="onEvent"></PG22>
        </section>
        <section
          class="PG_23"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_1.png')"
        >
          <PG23 @event="onEvent"></PG23>
        </section>
        <section
          class="PG_24"
          data-background-size="contain"
          data-background-video="https://desarrollo.factoriamedia.com/videos_tour/video_1_loop.mp4"
          :data-background-video-loop="true"
          :data-background-video-muted="true"
        >
          <PG24 @event="onEvent"></PG24>
        </section>
        <section
          class="PG_25"
          data-background-size="contain"
          data-background-video="https://desarrollo.factoriamedia.com/videos_tour/video_1_loop.mp4"
          :data-background-video-loop="true"
          :data-background-video-muted="true"
        >
          <PG25 @event="onEvent"></PG25>
        </section>
        <section
          class="PG_26"
          data-background-size="contain"
          :data-background-image="require('@/assets/presentacion/fondo_1.png')"
        >
          <PG26 @event="onEvent"></PG26>
        </section>
        <img
          src="@/assets/presentacion/on.png"
          class="audio-img on"
          @click="playAU()"
        />
      </div>
    </div>
  </div>
</template>

<script>
import fake3D from "../../vendor/fake3d/src";
import Reveal from "../../vendor/reveal/js/reveal";
import firebase from "firebase/app";
import "firebase/database";
import "firebase/firestore";
import "firebase/auth";
import getCurrentUser from "../../router/auth";
import { Howl, Howler } from "howler";

import { bus } from "../../main";

import PG1 from "./slides/pg_1";
import PG2 from "./slides/pg_2";
import PG3 from "./slides/pg_3";
import PG4 from "./slides/pg_4";
import PG5 from "./slides/pg_5";
import PG6 from "./slides/pg_6";
import PG7 from "./slides/pg_7";
import PG8 from "./slides/pg_8";
import PG9 from "./slides/pg_9";
import PG10 from "./slides/pg_10";
import PG11 from "./slides/pg_11";
import PG12 from "./slides/pg_12";
import PG13 from "./slides/pg_13";
import PG14 from "./slides/pg_14";
import PG15 from "./slides/pg_15";
import PG16 from "./slides/pg_16";
import PG17 from "./slides/pg_17";
import PG18 from "./slides/pg_18";
import PG19 from "./slides/pg_19";
import PG20 from "./slides/pg_20";
import PG21 from "./slides/pg_21";
import PG22 from "./slides/pg_22";
import PG23 from "./slides/pg_23";
import PG24 from "./slides/pg_24";
import PG25 from "./slides/pg_25";
import PG26 from "./slides/pg_26";

var sound = new Howl({
  src: [require("../../assets/presentacion/tech.mp3")],
  html5: true,
  loop: true,
  volume: 0.01,
  // volume: 1,
});

let id;

var realtimeDB = firebase.database();
var firestore = firebase.firestore();
window.Reveal = Reveal;
window.fake3D = fake3D;

export default {
  components: {
    PG1,
    PG2,
    PG3,
    PG4,
    PG5,
    PG6,
    PG7,
    PG8,
    PG9,
    PG10,
    PG11,
    PG12,
    PG13,
    PG14,
    PG15,
    PG16,
    PG17,
    PG18,
    PG19,
    PG20,
    PG21,
    PG22,
    PG23,
    PG24,
    PG25,
    PG26,
  },
  data() {
    return {
      user: null,
      userData: null,
      mouses: [],
      initializedReveal: false,
      color: null,
      isAdmin: false,
      currentPage: 1,
    };
  },
  firebase: {
    mouses: realtimeDB.ref("mouse"),
  },
  mounted() {
    this.$nextTick(async () => {
      var user = await getCurrentUser();
      this.user = user;

      var idTokenResult = await user.getIdTokenResult();

      this.isAdmin = idTokenResult.claims.admin;

      this.userData = await firestore.collection("users").doc(user.uid).get();
      this.userData = this.userData.data();
      var color = this.getRandomColor();
      this.color = color;

      // this.captureMouse(100);

      realtimeDB.ref("/users/" + user.uid).set({
        nombre: this.userData.nombre,
        color: color,
        id: user.uid,
        event: "Inicio",
        timestamp: Date.now(),
      });

      //add event on close
      window.addEventListener("beforeunload", () => {
        realtimeDB.ref("/mouse/" + user.uid).remove();
        realtimeDB.ref("/users/" + user.uid).remove();
      });

      Reveal.initialize({
        controls: false,
        progress: false,
        history: false,
        center: true,
        slideNumber: false,
        touch: true,
        keyboard: false,
        hash: false,
        preloadIframes: false,
        overview: false,
        transition: "fade", //none/fade/slide/convex/concave/zoom
        width: 1920,
        height: 1080,
        dependencies: [
          { src: require("../../vendor/plugin/transit/transit"), async: false },
          {
            src: require("../../vendor/plugin/appearance/appearance"),
            async: false,
          },
        ],
      });

      bus.$on("modal-open", () => {
        sound.pause();
      });
      bus.$on("modal-close", () => {
        sound.playing() ? null : sound.play();
      });

      Reveal.addEventListener("ready", (event) => {
        this.currentPage = event.indexh + 1;
      });

      Reveal.addEventListener("slidechanged", (event) => {
        this.currentPage = event.indexh + 1;
        switch (event.indexh) {
          case 1:
            id = sound.play();
            break;
          case 5:
            this.$refs.PG6.recibeEvento(this.currentPage);
            break;
        }
      });

      this.initializedReveal = true;
    });
  },
  methods: {
    playAU() {
      var x = document.querySelector(".audio-img");
      if (x.classList.contains("on")) {
        x.classList.remove("on");
        x.classList.add("off");
        x.src = require("@/assets/presentacion/off.png");
        sound.mute(true, id);
      } else {
        x.classList.remove("off");
        x.classList.add("on");
        x.src = require("@/assets/presentacion/on.png");
        sound.mute(false, id);
      }
    },
    getRandomColor() {
      var letters = "0123456789ABCDEF";
      var color = "#";
      for (var i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
      }
      return color;
    },
    onEvent(event) {
      realtimeDB.ref("/users/" + this.user.uid).update({
        event: event,
      });
    },
    // captureMouse(timeout) {
    //   window.addEventListener(
    //     "mousemove",
    //     (e) => {
    //       var element = document.querySelector("#scene");
    //       var scaleX =
    //         element.getBoundingClientRect().width / element.offsetWidth;
    //       var scaleY =
    //         element.getBoundingClientRect().height / element.offsetHeight;
    //       realtimeDB.ref("/mouse/" + this.user.uid).set({
    //         x: e.clientX / scaleX,
    //         y: e.clientY / scaleY,
    //         nombre: this.userData.nombre,
    //         color: this.color,
    //         id: this.user.uid,
    //         timestamp: Date.now(),
    //       });
    //     },
    //     { once: true }
    //   );
    //   setTimeout(() => {
    //     this.captureMouse(timeout);
    //   }, timeout);
    // },
  },
};
</script>

<style lang="scss">
@import "../../vendor/reveal/css/reveal.scss";
@import "../../vendor/reveal/css/theme/black.css";
@import "../../vendor/animate.css";
@import "../../vendor/appearance.css";

.admin {
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 100000;
  color: white;
  text-shadow: black 2px 2px 0px;
}

#container-presentacion {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  background-color: black;
}
.audio-img {
  position: absolute;
  bottom: 10px;
  right: 10px;
  cursor: pointer;
  width: 50px;
  z-index: 999999;
}
.reveal {
  height: 100vh;
  section,
  #background {
    img,
    video {
      max-width: unset;
      max-height: unset;
      margin: 0px;
      border: unset;
      box-shadow: unset;
      background: unset;
    }

    top: 0 !important;
  }
}

#background {
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

#container {
  width: 100%;
}

svg {
  margin: 0px;
}
.reveal .slides {
  pointer-events: auto !important;
}
.mouse {
  position: absolute;
  z-index: 100;
  transition: all 50ms;
  opacity: 0.5;
  pointer-events: none;
  .cursor {
    width: 25px;
    height: 25px;
    background-color: red;
    -webkit-mask-image: url("~@/assets/presentacion/mouse.svg");
    mask-image: url("~@/assets/presentacion/mouse.svg");
    mask-size: contain;
    mask-repeat: no-repeat;
  }
  h1 {
    font-size: 12px;
  }
}

.lds-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -100px; /* half width*/
  margin-top: -100px; /* half height*/
  display: inline-block;
  transform: translateZ(1px);
}

@keyframes ldio-152srjlg8i6 {
  0% {
    top: 96px;
    left: 96px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 18px;
    left: 18px;
    width: 156px;
    height: 156px;
    opacity: 0;
  }
}
.ldio-152srjlg8i6 div {
  position: absolute;
  border-width: 4px;
  border-style: solid;
  opacity: 1;
  border-radius: 50%;
  animation: ldio-152srjlg8i6 0.8s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.ldio-152srjlg8i6 div:nth-child(1) {
  border-color: #e90c59;
  animation-delay: 0s;
}
.ldio-152srjlg8i6 div:nth-child(2) {
  border-color: #ffffff;
  animation-delay: -0.4s;
}
.loadingio-spinner-ripple-505tgqawfzb {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #ffffff00;
}
.ldio-152srjlg8i6 {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-152srjlg8i6 div {
  box-sizing: content-box;
}
</style>
