<template>
  <div class="PG_16">
    <img
      src="@/assets/presentacion/PG_2/logo.png"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/PG_2/visita.png"
      class="animated fadeInRight visita"
    />
    <img
      src="@/assets/presentacion/PG_15/titulo.png"
      class="animated fadeInUp titulo"
    />
    <img
      src="@/assets/presentacion/PG_15/video_lineas_llenado.png"
      class="animated fadeInDown subtitulo"
    />
    <img
      src="@/assets/presentacion/PG_15/subtit.png"
      class="animated fadeInDown instclic"
    />
    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 16 - Continuar')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 16 - Volver')"
    />

    <img
      src="@/assets/presentacion/PG_15/video_1.png"
      class="video_1 animated bounceIn slow"
      @click="clickVideo('1')"
      :style="{ display: slide.boton_retornable ? 'block' : 'none' }"
    />
    <img
      src="@/assets/presentacion/PG_15/video_2.png"
      class="video_2 animated bounceIn slow"
      @click="clickVideo('2')"
      :style="{ display: slide.boton_no_retornable ? 'block' : 'none' }"
    />
    <modal @close="showModal1 = false" v-if="showModal1">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/llenado_retornable.mp4"
        slot="body"
        @ended="onVideoEnded('1')"
      ></video>
    </modal>
    <modal @close="showModal2 = false" v-if="showModal2">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/llenado_no_retornable.mp4"
        slot="body"
        @ended="onVideoEnded('2')"
      ></video>
    </modal>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import Modal from "../../../components/modal.vue";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      showModal1: false,
      showModal2: false,
    };
  },
  methods: {
    clickVideo(video) {
      this.$emit("event", "Slide 16 - Ver Video " + video);
      this["showModal" + video] = true;
    },
    onVideoEnded(video) {
      this.$emit("event", `Slide 16 - Video ${video} completado`);
    },
    hideAll() {
      this.showModal1 = false;
      this.showModal2 = false;
    },
  },
  watch: {
    slide() {
      this.hideAll();
    },
  },
  components: {
    Modal,
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_16"),
  },
};
</script>

<style lang="scss">
.PG_16 {
  .logo {
    position: absolute;
    left: 125px;
    top: 60px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 679px;
    top: 80px;
  }
  .subtitulo {
    position: absolute;
    left: 773px;
    top: 269px;
  }
  .instclic {
    position: absolute;
    left: 769px;
    top: 402px;
  }
 .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
  // .v_lineas_llenado {
  //   position: absolute;
  //   left: 191px;
  //   top: 534px;
  // }
  .video_1 {
    position: absolute;
    left: 684px;
    top: 505px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .video_1:hover {
    transform: scale(1.1);
  }
  .video_2 {
    position: absolute;
    left: 973px;
    top: 505px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .video_2:hover {
    transform: scale(1.1);
  }
  .video {
    position: absolute;
    left: 200px;
    top: 419px;
  }
}
</style>
