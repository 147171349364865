<template>
  <div class="PG_24">
    <img
      src="@/assets/presentacion/PG_12/logo.png"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/PG_12/visita.png"
      class="animated fadeInRight visita"
    />
    <img
      src="@/assets/presentacion/PG_17/instruccion.png"
      class="animated fadeInUp instruccion"
    />
    <img
      src="@/assets/presentacion/PG_26/texto_nuestra_gente.png"
      class="animated fadeInDown texto_nuestra_gente"
    />
    <img
      src="@/assets/presentacion/PG_26/boton_1.png"
      class="animated bounceIn slow boton_1"
      @click="clickVideo(1)"
      :style="{ display: slide.boton_nuestra_gente ? 'block' : 'none' }"
    />

    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 24 - Continuar')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 24 - Volver')"
    />

    <modal @close="showModal1 = false" v-if="showModal1">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/nuestra_gente.mp4"
        slot="body"
        @ended="onVideoEnded('1')"
      ></video>
    </modal>

    <!-- <modal @close="showModal1 = false" v-if="showModal1">
      <img
        class="titulo-modal"
        src="@/assets/presentacion/PG_26/titulo2.png"
        slot="body"
      />
      <img
        class="img-modal"
        src="@/assets/presentacion/PG_24/foto.png"
        slot="body"
      />
    </modal> -->
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import Modal from "../../../components/modal.vue";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      showModal1: false,
    };
  },
  methods: {
    clickImagen(imagen) {
      this.$emit("event", "Slide 24 - Ver Imagen " + imagen);
      this["showModal" + imagen] = true;
    },
    clickVideo(video) {
      this.$emit("event", "Slide 24 - Ver Video " + video);
      this["showModal" + video] = true;
    },
    onVideoEnded(video) {
      this.$emit("event", `Slide 24 - Video ${video} completado`);
    },
    hideAll() {
      this.showModal1 = false;
    },
  },
  watch: {
    slide() {
      this.hideAll();
    },
  },
  components: {
    Modal,
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_24"),
  },
};
</script>

<style lang="scss">
.PG_24 {
  .logo {
    position: absolute;
    left: 135px;
    top: 60px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 668px;
    top: 80px;
  }
  .instruccion {
    position: absolute;
    left: 770px;
    top: 390px;
  }
  .texto_nuestra_gente {
    position: absolute;
    left: 730px;
    top: 80px;
  }
  .boton_1 {
    position: absolute;
    left: 829px;
    top: 495px;
    transition: all 0.5s ease;
    cursor: pointer;
  }
  .boton_1:hover {
    transform: scale(1.2);
  }
  .modal-body {
    width: 1920px;
    height: 1080px;
  }
  .titulo-modal {
    position: absolute;
    left: 730px;
    top: 65px;
  }

  .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
