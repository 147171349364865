<template>
  <div>
    <a href="/formato.xlsx" download=""> Descargar formato</a>

    <input
      ref="excel-upload-input"
      class="excel-upload-input"
      type="file"
      accept=".xlsx, .xls"
      @change="handleClick"
    />
    <div
      class="drop"
      @drop="handleDrop"
      @dragover="handleDragover"
      @dragenter="handleDragover"
    >
      Arrastra aquí el archivo excel
      <el-button
        :loading="loading"
        style="margin-left: 16px"
        size="mini"
        type="primary"
        @click="handleUpload"
      >
        Browse
      </el-button>
    </div>

    <el-table
      :data="excelData.results"
      border
      highlight-current-row
      style="width: 100%; margin-top: 20px"
      empty-text="No hay datos"
    >
      <el-table-column
        v-for="item of headers"
        :key="item"
        :prop="item"
        :label="item"
      />
      <el-table-column label="Operaciones">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >Eliminar</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-button
      type="success"
      class="add-button"
      @click="uploadList"
      :loading="loading"
    >
      Registrar usuarios agregados</el-button
    >

    <el-card class="agregar">
      <div>
        <span>Agregar manualmente</span>
        <el-form label-width="100px" :model="formAdd">
          <el-form-item label="Nombres">
            <el-input v-model="formAdd.nombres"></el-input>
          </el-form-item>
          <el-form-item label="Apellidos">
            <el-input v-model="formAdd.apellidos"></el-input>
          </el-form-item>
          <el-form-item label="Institución educativa">
            <el-input v-model="formAdd.institucion"></el-input>
          </el-form-item>

          <el-form-item label="Puntos ganados">
            <el-input v-model="formAdd.puntosGanados"></el-input>
          </el-form-item>
          <el-form-item label="CLAVE DE INGRESO (Minimo 6 caracteres)">
            <el-input v-model="formAdd.clave"></el-input>
          </el-form-item>
          <el-form-item label="Email de ingreso">
            <el-input type="email" v-model="formAdd.email"></el-input>
          </el-form-item>
          <el-button type="primary" @click="add">Agregar</el-button>
        </el-form>
      </div>
    </el-card>
  </div>
</template>

<script>
import XLSX from "xlsx";
import axios from "axios";
export default {
  props: {
    beforeUpload: Function, // eslint-disable-line
    onSuccess: Function, // eslint-disable-line
  },
  data() {
    return {
      loading: false,
      excelData: {
        header: null,
        results: [],
      },
      headers: [
        "NOMBRES",
        "APELLIDOS",
        "INSTITUCION EDUCATIVA",
        "PUNTOS GANADOS",
        "CLAVE DE INGRESO (Minimo 6 caracteres)",
        "EMAIL DE INGRESO",
      ],
      formAdd: {
        nombres: "",
        apellidos: "",
        institucion: "",
        puntosGanados: "",
        clave: "",
        email: "",
      },
    };
  },
  methods: {
    add() {
      this.excelData.results.push({
        NOMBRES: this.formAdd.nombres,
        APELLIDOS: this.formAdd.apellidos,
        "INSTITUCION EDUCATIVA": this.formAdd.institucion,
        "PUNTOS GANADOS": this.formAdd.puntosGanados,
        "CLAVE DE INGRESO (Minimo 6 caracteres)": this.formAdd.clave,
        "EMAIL DE INGRESO": this.formAdd.email,
      });
      this.formAdd = {
        nombres: "",
        apellidos: "",
        institucion: "",
        puntosGanados: "",
        clave: "",
        email: "",
      };
    },
    async uploadList() {
      if (this.excelData.results.length > 0) {
        this.loading = true;
        axios
          .post(
            "https://us-central1-arca-tour-pus.cloudfunctions.net/uploadUsers",
            { users: this.excelData.results }
          )
          .then((res) => {
            this.loading = false;
            this.excelData.results = [];
          })
          .catch((e) => {
            this.loading = false;
            this.excelData.results = [];
            console.log(e.response);
            this.$alert(
              e.response.data.message,
              "Error al registrar los usuarios",
              {
                type: "error",
              }
            );
          });
      }
    },
    handleDelete(index, row) {
      this.excelData.results.splice(index, 1);
    },
    generateData({ header, results }) {
      this.excelData.header = header;
      this.excelData.results = results;
      this.onSuccess && this.onSuccess(this.excelData);
    },
    handleDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      if (this.loading) return;
      const files = e.dataTransfer.files;
      if (files.length !== 1) {
        this.$message.error("Only support uploading one file!");
        return;
      }
      const rawFile = files[0]; // only use files[0]
      if (!this.isExcel(rawFile)) {
        this.$message.error(
          "Only supports upload .xlsx, .xls, .csv suffix files"
        );
        return false;
      }
      this.upload(rawFile);
      e.stopPropagation();
      e.preventDefault();
    },
    handleDragover(e) {
      e.stopPropagation();
      e.preventDefault();
      e.dataTransfer.dropEffect = "copy";
    },
    handleUpload() {
      this.$refs["excel-upload-input"].click();
    },
    handleClick(e) {
      const files = e.target.files;
      const rawFile = files[0]; // only use files[0]
      if (!rawFile) return;
      this.upload(rawFile);
    },
    upload(rawFile) {
      this.$refs["excel-upload-input"].value = null; // fix can't select the same excel
      if (!this.beforeUpload) {
        this.readerData(rawFile);
        return;
      }
      const before = this.beforeUpload(rawFile);
      if (before) {
        this.readerData(rawFile);
      }
    },
    readerData(rawFile) {
      this.loading = true;
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = XLSX.read(data, { type: "array" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          const header = this.getHeaderRow(worksheet);
          const results = XLSX.utils.sheet_to_json(worksheet);
          this.generateData({ header, results });
          this.loading = false;
          resolve();
        };
        reader.readAsArrayBuffer(rawFile);
      });
    },
    getHeaderRow(sheet) {
      const headers = [];
      const range = XLSX.utils.decode_range(sheet["!ref"]);
      let C;
      const R = range.s.r;
      /* start in the first row */
      for (C = range.s.c; C <= range.e.c; ++C) {
        /* walk every column in the range */
        const cell = sheet[XLSX.utils.encode_cell({ c: C, r: R })];
        /* find the cell in the first row */
        let hdr = "UNKNOWN " + C; // <-- replace with your desired default
        if (cell && cell.t) hdr = XLSX.utils.format_cell(cell);
        headers.push(hdr);
      }
      return headers;
    },
    isExcel(file) {
      return /\.(xlsx|xls|csv)$/.test(file.name);
    },
  },
};
</script>

<style scoped>
.excel-upload-input {
  display: none;
  z-index: -9999;
}
.drop {
  border: 2px dashed #bbb;
  width: 600px;
  height: 160px;
  line-height: 160px;
  margin: 0 auto;
  font-size: 24px;
  border-radius: 5px;
  text-align: center;
  color: #bbb;
  position: relative;
}
.agregar {
  margin: 30px 0;
  height: 50vh;
  overflow-y: auto;
}
.add-button {
  margin-top: 20px;
}
</style>
