<template>
  <div>
    <el-container class="container-opciones">
      <el-button
        :loading="downloadLoading"
        style="margin: 0 0 20px 20px"
        type="primary"
        icon="el-icon-document"
        @click="handleDownload"
      >
        Exportar Excel
      </el-button>
      <FilenameOption v-model="filename" />
      <AutoWidthOption v-model="autoWidth" />
      <BookTypeOption v-model="bookType" />
    </el-container>
    <el-button
      type="danger"
      :disabled="multipleSelection.length == 0"
      @click="deleteUsers"
      >Eliminar seleccionados</el-button
    >
    <el-table
      :data="users"
      border
      highlight-current-row
      style="width: 100%; margin-top: 20px"
      empty-text="No hay datos"
      @selection-change="handleSelectionChange"
    >
      <el-table-column type="selection" width="55"> </el-table-column>
      <el-table-column
        v-for="item of headers"
        :key="item"
        :prop="item"
        :label="item"
      />
      <el-table-column label="Operaciones">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            >Eliminar</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import FilenameOption from "./components/FilenameOption";
import AutoWidthOption from "./components/AutoWidthOption";
import BookTypeOption from "./components/BookTypeOption";
import axios from "axios";

var db = firebase.firestore();

export default {
  data() {
    return {
      headers: ["nombre", "apellido", "institucion"],
      downloadLoading: false,
      filename: "",
      autoWidth: true,
      bookType: "xlsx",
      multipleSelection: [],
    };
  },
  components: { FilenameOption, AutoWidthOption, BookTypeOption },
  firestore: {
    users: db.collection("users"),
  },
  methods: {
    handleSelectionChange(val) {
      console.log(val);
      this.multipleSelection = val.map((x) => ({ uid: x.id }));
    },
    handleDownload() {
      this.downloadLoading = true;
      import("../../vendor/Export2Excel").then((excel) => {
        excel.export_json_to_excel({
          header: this.headers,
          data: this.formatJson(this.headers, this.users),
          filename: this.filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
        this.downloadLoading = false;
      });
    },
    handleDelete(index, row) {
      this.$confirm("¿Estás seguro de eliminar este usuario?", "Confirmar", {
        confirmButtonText: "Eliminar",
        cancelButtonText: "Cancelar",
        type: "warning",
      })
        .then(() => {
          axios.post(
            "https://us-central1-arca-tour-pus.cloudfunctions.net/uploadUsers/delete-user",
            { uid: row.id }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteUsers() {
      this.$confirm(
        `¿Estás seguro de eliminar ${this.multipleSelection.length} usuarios?`,
        "Confirmar",
        {
          confirmButtonText: "Eliminar",
          cancelButtonText: "Cancelar",
          type: "warning",
        }
      )
        .then(() => {
          axios.post(
            "https://us-central1-arca-tour-pus.cloudfunctions.net/uploadUsers/delete-users",
            { users: this.multipleSelection }
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
};
</script>

<style>
.date-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
}
.container-opciones {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
</style>
