<template>
  <div class="container">
    <split-pane :min-percent="10" :default-percent="70" split="vertical">
      <template slot="paneL">
        <split-pane split="horizontal" :min-percent="10" :default-percent="20">
          <template slot="paneL">
            <div class="usuarios">
              <div
                class="usuario"
                v-for="usuario in usuarios"
                :key="usuario.id"
                :style="{ color: usuario.color }"
              >
                <h1>
                  {{ usuario.nombre }} - <span>{{ usuario.event }}</span>
                </h1>
              </div>
            </div>
          </template>
          <template slot="paneR">
            <iframe
              class="presentacion"
              src="https://tourvirtualacl.com/presentacion"
              frameborder="0"
            ></iframe>
          </template>
        </split-pane>
      </template>
      <template slot="paneR">
        <el-tree
          empty-text="Cargando..."
          :data="tree"
          :props="defaultProps"
          show-checkbox
          node-key="id"
          @check-change="check"
          :default-checked-keys="defaultKeys"
          class="items"
        ></el-tree>
      </template>
    </split-pane>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";

var realtimeDB = firebase.database();
var db = firebase.firestore();

export default {
  mounted() {
    this.$nextTick(async () => {
      var data = await db
        .collection("presentacion")
        .where(firebase.firestore.FieldPath.documentId(), "!=", "bienvenida")
        .get();

      this.slides = data.docs.sort(this.naturalCompare).map((doc) => {
        var data = doc.data();
        data.id = doc.id;
        return data;
      });

      this.slides.forEach((slide) => {
        Object.keys(slide)
          .filter((key) => key != "id")
          .forEach((key) => {
            if (slide[key]) {
              this.defaultKeys.push(slide.id + key);
            }
          });
      });
    });
  },
  data() {
    return {
      slides: [],
      defaultKeys: [],
      defaultProps: {
        children: "children",
        label: "label",
      },
      usuarios: [],
    };
  },
  firebase: {
    usuarios: realtimeDB.ref("users"),
  },
  methods: {
    check(val, checked) {
      if (val.children) {
        var map = {};
        val.children.forEach((child, index) => {
          map[child.label] = checked;
        });
        db.collection("presentacion").doc(val.children[0].parentId).update(map);
      } else {
        var map = {};
        map[val.label] = checked;
        db.collection("presentacion").doc(val.parentId).update(map);
      }
    },
    naturalCompare(a, b) {
      var ax = [],
        bx = [];

      a.id.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
        ax.push([$1 || Infinity, $2 || ""]);
      });
      b.id.replace(/(\d+)|(\D+)/g, function (_, $1, $2) {
        bx.push([$1 || Infinity, $2 || ""]);
      });

      while (ax.length && bx.length) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = an[0] - bn[0] || an[1].localeCompare(bn[1]);
        if (nn) return nn;
      }

      return ax.length - bx.length;
    },
  },

  computed: {
    tree() {
      return this.slides.map((item, index) => {
        return {
          id: item.id,
          label: item.id,
          children: Object.keys(item)
            .filter((key) => key != "id")
            .map((key) => ({
              label: key,
              parentId: item.id,
              id: item.id + key,
            }))
            .sort(this.naturalCompare),
        };
      });
    },
  },
};
</script>

<style lang="scss">
@import url("https://cdn.jsdelivr.net/npm/rrweb@latest/dist/rrweb.min.css");
.container {
  height: 100vh;
  background-color: #9a0c31;
  .presentacion {
    height: 100%;
    width: 100%;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
  .usuarios {
    max-height: 100%;
    width: 100%;
    overflow-y: auto;
    padding: 15px 30px;
    box-sizing: border-box;
    .usuario {
      background-color: rgba(0, 0, 0, 0.5);
      text-align: left;
      margin-top: 10px;
      h1 {
        margin: 0;
        font-size: 18px;
      }
    }
  }
  .items {
    max-height: 90vh;
    overflow-y: auto;
  }
}
</style>
