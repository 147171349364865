<template>
  <div class="PG_5">
    <img
      src="@/assets/presentacion/logoWhi.svg"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/logoDer.svg"
      class="animated fadeInRight logoD"
    />
    <img
      src="@/assets/presentacion/PG_5/11.png"
      class="animated bounceIn slow years_11"
    />

    <img
      src="@/assets/presentacion/PG_5/ver_video.png"
      class="animated bounceIn slow video"
      @click="clickVideo()"
      :style="{ display: slide.ver_video ? 'block' : 'none' }"
    />
    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 5 - Continuar')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
    <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 5 - Volver')"
    />
    <modal @close="showModal = false" v-if="showModal">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        src="https://desarrollo.factoriamedia.com/videos_tour/video_2.mp4"
        slot="body"
        @ended="onVideoEnded"
      ></video>
    </modal>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import Modal from "../../../components/modal.vue";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      showModal: false,
    };
  },
  methods: {
    clickVideo() {
      this.$emit("event", "Slide 5 - Ver Video");
      this.showModal = true;
    },
    onVideoEnded() {
      this.$emit("event", "Slide 5 - Video completado");
    },
  },
  components: {
    Modal,
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_5"),
  },
};
</script>

<style lang="scss">
.PG_5 {
  .logo {
    position: absolute;
    left: 126px;
    top: 62px;
    width: 207px;
    height: 134px;
  }
  .logoD {
    position: absolute;
    left: 1642px;
    top: 76px;
    width: 193px;
    height: 68px;
  }
  .years_11 {
    position: absolute;
    left: 710px;
    top: 315px;
  }
  .video {
    position: absolute;
    left: 830px;
    top: 830px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .video:hover {
    transform: scale(1.1);
  }
  // .continuar {
  //   position: absolute;
  //   left: 830px;
  //   top: 935px;
  //   cursor: pointer;
  //   transition: all 0.5s ease;
  // }
  // .continuar:hover {
  //   transform: scale(1.1);
  // }
  .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
