<template>
  <div class="PG_9">
    <img
      src="@/assets/presentacion/PG_2/logo.png"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/PG_2/visita.png"
      class="animated fadeInRight visita"
    />
    <img
      src="@/assets/presentacion/PG_9/pregunta.png"
      class="animated bounceIn slower pregunta"
    />

    <img
      src="@/assets/presentacion/PG_9/respuesta.png"
      class="animated fadeIn respuesta"
    />
    <el-input
      placeholder="Escribe aquí..."
      v-model="respuesta"
      class="seleccionar animated fadeIn"
      @change="$emit('event', 'Slide 9 - Escribiendo respuesta')"
    >
    </el-input>
    <img
      src="@/assets/presentacion/PG_9/finalizar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 9 - Finalizar') && saveCuestionary()"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 9 - Volver')"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      selected: [],
      intereses: "",
      time: 0,
      respuesta: "",
    };
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_9"),
  },
  mounted() {
    this.time = Date.now();
  },
  methods: {
    toggle(id) {
      if (this.selected.includes(id)) {
        this.selected = this.selected.filter((item) => item !== id);
      } else {
        this.selected.push(id);
      }
    },
    async saveCuestionary() {
      var user = firebase.auth().currentUser;
      var userData = await db.collection("users").doc(user.uid).get();

      var date = new Date();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var fecha = month + "" + day + "" + year + "";

      await db
        .collection("cuestionarios")
        .doc(fecha)
        .collection("cuestionario_2")
        .doc(user.uid)
        .set({
          respuesta: this.respuesta,
          duracion: Date.now() - this.time,
          nombre: userData.data().nombre,
        });
    },
  },
};
</script>

<style lang="scss">
.PG_9 {
  .logo {
    position: absolute;
    left: 125px;
    top: 60px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .pregunta {
    position: absolute;
    left: 555px;
    top: 265px;
  }

  .respuesta {
    position: absolute;
    left: 935px;
    top: 650px;
  }

  .seleccionar {
    position: absolute;
    left: 540px;
    top: 685px;
    height: 50px;
    width: 910px;
    input {
      border-radius: 25px;
      font-size: 27px;
      padding: 30px;
    }
  }

  .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
