<template>
  <transition name="modal">
    <div>
      <div @keydown.esc="$emit('close')" tabindex="0">
        <div class="modal-body">
          <slot name="body" @keydown.esc="$emit('close')"> default body </slot>
        </div>
      </div>
      <div
        class="modal-mask"
        @click="$emit('close')"
        @keydown.esc="$emit('close')"
      ></div>
    </div>
  </transition>
</template>

<script>
import { bus } from "../main";

export default {
  mounted(){
    bus.$emit("modal-open")
    console.log("a")
  },
  beforeDestroy(){
    bus.$emit("modal-close")
    console.log("b")
  }
};
</script>

<style lang="scss">
.modal-mask {
  position: absolute;
  z-index: 9998;
  top: -2px;
  left: -2px;
  width: 1930px;
  height: 1090px;
  transition: opacity 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  background-color: rgba(0, 0, 0, 0.8);
}

.modal-body {
  width: 100%;
  height: 1080px;
  display: flex;
  justify-content: center;
  align-items: center;

  video {
    width: 1600px;
    height: 900px;
  }
  img {
    height: 720px;
  }
  video,
  img {
    position: absolute;
    z-index: 10000;
  }
  .titulo-modal {
    height: unset;
    position: absolute;
    z-index: 10010;
  }
}

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
