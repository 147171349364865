<template>
  <div class="PG_17">
    <img
      src="@/assets/presentacion/PG_6/logo.png"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/PG_6/visitas.png"
      class="animated fadeInRight visitas"
    />
    <img
      src="@/assets/presentacion/PG_6/mapa.png"
      class="mapa"
    />

    <div
      class="proceso_embotellado"
      :style="{
        display: slide.boton_proceso_embotellado ? 'block' : 'none',
      }"
    >
      <img
        src="@/assets/presentacion/PG_16/boton_marron.png"
        class="animated zoomIn boton_marron"
      />
      <img
        src="@/assets/presentacion/PG_16/proceso_embotellado.png"
        class="animated zoomIn imagen_proceso_embotellado"
      />
    </div>

    <div
      class="botones_rojos"
      :style="{
        display: slide.botones_rojos ? 'block' : 'none',
      }"
    >
      <img
        src="@/assets/presentacion/PG_16/boton.png"
        class="animated bounceIn slow boton_rojo1"
      />
      <img
        src="@/assets/presentacion/PG_16/boton.png"
        class="animated bounceIn slow boton_rojo2"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_16/retornable.png"
      class="animated bounceIn slow retornable navigate-next"
      @click="$emit('event', 'Slide 17 - Continuar')"
      :style="{ display: slide.boton_retornable ? 'block' : 'none' }"
    />
    <img
      src="@/assets/presentacion/PG_16/no_retornable.png"
      class="animated bounceIn slow no_retornable"
      :style="{ display: slide.boton_no_retornable ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 17 - Volver')"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import Modal from "../../../components/modal.vue";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
    };
  },

  components: {
    Modal,
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_17"),
  },
};
</script>

<style lang="scss">
.PG_17 {
  .logo {
    position: absolute;
    left: 125px;
    top: 60px;
  }
  .visitas {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .mapa {
    position: absolute;
    left: 105px;
    top: 215px;
  }
  .boton_marron {
    position: absolute;
    left: 871px;
    top: 402px;
  }
  .imagen_proceso_embotellado {
    position: absolute;
    left: 583px;
    top: 441px;
    z-index: 5;
  }
  .boton_rojo1 {
    position: absolute;
    left: 895px;
    top: 322px;
  }
  .boton_rojo2 {
    position: absolute;
    left: 1103px;
    top: 474px;
  }
  .retornable {
    position: absolute;
    left: 588px;
    top: 651px;
    cursor: pointer;
   transition: all 0.5s ease;
  }
  .retornable:hover{
    transform: scale(1.1);
  }
  .no_retornable {
    position: absolute;
    left: 756px;
    top: 625px;
  }
  .volver {
    position: absolute;
    left: 788px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
