<template>
  <div class="PG_1">
    <video
      preload="auto"
      data-autoplay
      @ended="nextPage"
      data-src="https://desarrollo.factoriamedia.com/videos_tour/titulo1.mp4"
    ></video>

    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 1 - Continuar')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"      
    />
    
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
var db = firebase.firestore();

export default {
  data() {
    return {
      slide: {},
    };
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_1"),
  },
  methods: {
    nextPage() {
      window.Reveal.next();
    },
    backPage() {
      window.Reveal.left();
    },
  },
};
</script>

<style lang="scss">
.PG_1 {
  .continuar {
    position: absolute;
    left: 828px;
    top: 891px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.2);
  }

}
</style>
