<template>
  <div>
    <el-container class="date-container">
      <el-date-picker v-model="fecha" type="date" placeholder="Elige un día">
      </el-date-picker>
      <el-select v-model="cuestionario">
        <el-option value="cuestionario_1">Cuestionario 1</el-option>
        <el-option value="cuestionario_2">Cuestionario 2</el-option>
        <el-option value="cuestionario_3">Cuestionario 3</el-option>
        <el-option value="cuestionario_4">Cuestionario 4</el-option>
      </el-select>
    </el-container>
    <el-container class="container-opciones">
      <el-button
        :loading="downloadLoading"
        style="margin: 0 0 20px 20px"
        type="primary"
        icon="el-icon-document"
        @click="handleDownload"
      >
        Exportar Excel
      </el-button>
      <FilenameOption v-model="filename" />
      <AutoWidthOption v-model="autoWidth" />
      <BookTypeOption v-model="bookType" />
    </el-container>
    <el-table
      :data="preparedData"
      border
      highlight-current-row
      style="width: 100%; margin-top: 20px"
      empty-text="No hay datos"
    >
      <el-table-column
        v-for="item of headers"
        :key="item"
        :prop="item"
        :label="item"
      />
    </el-table>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import moment from "moment";
import FilenameOption from "./components/FilenameOption";
import AutoWidthOption from "./components/AutoWidthOption";
import BookTypeOption from "./components/BookTypeOption";

var db = firebase.firestore();

const data = db.collection("cuestionarios");

export default {
  data() {
    return {
      loading: false,
      headers: [],
      preparedData: [],
      fecha: new Date(),
      cuestionario: "cuestionario_1",
      downloadLoading: false,
      filename: "",
      autoWidth: true,
      bookType: "xlsx",
    };
  },
  components: { FilenameOption, AutoWidthOption, BookTypeOption },

  methods: {
    handleDownload() {
      this.downloadLoading = true;
      import("../../vendor/Export2Excel").then((excel) => {
        console.log(excel);
        excel.export_json_to_excel({
          header: this.headers,
          data: this.formatJson(this.headers, this.preparedData),
          filename: this.filename,
          autoWidth: this.autoWidth,
          bookType: this.bookType,
        });
        this.downloadLoading = false;
      });
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) =>
        filterVal.map((j) => {
          return v[j];
        })
      );
    },
  },
  watch: {
    fecha: {
      // call it upon creation too
      immediate: true,
      handler(date) {
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var fecha = month + "" + day + "" + year + "";

        this.$bind(
          "results",
          data.doc(fecha).collection(this.cuestionario)
        ).then((data) => {
          if (data != null && data.length > 0) {
            this.headers = Object.keys(data[0]);
          }
          this.preparedData = data.map((e) => {
            e["duracion"] = moment
              .duration(e["duracion"], "milliseconds")
              .locale("es")
              .humanize();
            if (e["seleccionado"])
              e["seleccionado"] = e["seleccionado"].join(" - ");
            return e;
          });
        });
      },
    },
    cuestionario: {
      handler(cuestionario) {
        var date = this.fecha;
        var day = date.getDate();
        var month = date.getMonth() + 1;
        var year = date.getFullYear();
        var fecha = month + "" + day + "" + year + "";

        this.$bind("results", data.doc(fecha).collection(cuestionario)).then(
          (data) => {
            if (data != null && data.length > 0) {
              this.headers = Object.keys(data[0]);
            }
            this.preparedData = data.map((e) => {
              e["duracion"] = moment
                .duration(e["duracion"], "milliseconds")
                .locale("es")
                .humanize();
              if (e["seleccionado"])
                e["seleccionado"] = e["seleccionado"].join(" - ");
              return e;
            });
          }
        );
      },
    },
  },
};
</script>

<style>
.date-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px;
}
.container-opciones {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}
</style>
