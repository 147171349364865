<template>
  <div class="PG_12">
    <img
      src="@/assets/presentacion/PG_6/logo.png"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/PG_6/visitas.png"
      class="animated fadeInRight visita"
    />
    <img
      src="@/assets/presentacion/PG_11/titulo.png"
      class="animated fadeInUp titulo"
    />
    <img
      src="@/assets/presentacion/PG_11/video_portafolio.png"
      class="animated fadeIn subtitulo"
    />
    <img
      src="@/assets/presentacion/PG_11/bebidas.png"
      class="animated fadeIn portaf"
      @click="clickFoto('1')"
    />
    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 12 - Continuar')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 12 - Volver')"
    />

      <viewer :images="images" slot="body"> </viewer>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import Modal from "../../../components/modal.vue";

var db = firebase.firestore();
export default {
  data() {
    return {
      images: [
        require("@/assets/fotos/foto_11_1.jpg"),
        require("@/assets/fotos/foto_11_2.jpg"),
        require("@/assets/fotos/foto_11_3.jpg"),
        require("@/assets/fotos/foto_11_4.jpg"),
      ],
      slide: {},
      showFoto1: false,
      showFoto2: false,
      showFoto3: false,
      showFoto4: false,
    };
  },
  methods: {
    clickFoto(foto) {
      this.$viewerApi({
        images: this.images,
        options: {
          backdrop: true,
          title: false,
          button: false,
          movable: false,
          zoomable: false,
          scalable:false,
          toolbar: {
            zoomIn: 0,
            zoomOut: 0,
            oneToOne: 0,
            reset: 0,
            prev: 1,
            play: 0,
            next: 1,
            rotateLeft: 0,
            rotateRight: 0,
            flipHorizontal: 0,
            flipVertical: 0,
          },
        }
      });
      this.$emit("event", "Slide 12 - Ver foto " + foto);
      this["showFoto" + foto] = true;
    },
    hideAll() {
      this.showFoto1 = false;
      this.showFoto2 = false;
      this.showFoto3 = false;
      this.showFoto4 = false;
    },
  },
  watch: {
    slide() {
      this.hideAll();
    },
  },
  components: {
    Modal,
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_12"),
  },
};
</script>

<style lang="scss">
.PG_12 {
  .logo {
    position: absolute;
    left: 125px;
    top: 60px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 521px;
    top: 80px;
  }
  .subtitulo {
    position: absolute;
    left: 769px;
    top: 402px;
  }
  .portaf {
    position: absolute;
    left: 370px;
    top: 400px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .portaf:hover {
    transform: scale(1.03);
  }
  .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
  .foto_1 {
    position: absolute;
    left: 516px;
    top: 360px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .foto_1:hover {
    transform: scale(1.1);
  }
  .foto_2 {
    position: absolute;
    left: 315px;
    top: 635px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .foto_2:hover {
    transform: scale(1.1);
  }
  .foto_3 {
    position: absolute;
    left: 585px;
    top: 635px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .foto_3:hover {
    transform: scale(1.1);
  }
  .foto_4 {
    position: absolute;
    left: 855px;
    top: 635px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .foto_4:hover {
    transform: scale(1.1);
  }
}
</style>
