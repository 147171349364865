<template>
  <div class="PG_6">
    <video
      class="video"
      data-autoplay
      src="https://desarrollo.factoriamedia.com/videos_tour/video_mapa.mp4"
      type="video/mp4"
      @ended="onVideoEnded('mapa')"
    ></video>
    <div
      class="animated fadeIn slow"
      :style="{ display: videoEnded ? 'block' : 'none' }"
    >
      <img src="@/assets/presentacion/PG_6/mapa.png" class="mapa" />
      <img src="@/assets/presentacion/PG_6/logo.png" class="logo" />
      <img src="@/assets/presentacion/PG_6/visitas.png" class="visitas" />

      <img
        src="@/assets/presentacion/PG_6/boton.png"
        class="animated boton_recorrido pulsate-fwd"
        @click="hideAll(), (showRecorrido = true)"
        :style="{
          display: slide.boton_1_inicio_recorrido ? 'block' : 'none',
        }"
      />
      <img
        src="@/assets/presentacion/PG_6/boton.png"
        class="animated boton_calles pulsate-fwd"
        @click="hideAll(), (showCalles = true)"
        :style="{
          display: slide.boton_2_calles_e_instalaciones ? 'block' : 'none',
        }"
      />
      <img
        src="@/assets/presentacion/PG_6/boton.png"
        class="animated boton_interiores pulsate-fwd"
        @click="hideAll(), (showInteriores = true)"
        :style="{ display: slide.boton_3_interiores ? 'block' : 'none' }"
      />
      <img
        src="@/assets/presentacion/PG_6/boton.png"
        class="animated boton_certificacion pulsate-fwd"
        @click="hideAll(), (showCertificacion = true)"
        :style="{ display: slide.boton_4_certificacion ? 'block' : 'none' }"
      />

      <img
        src="@/assets/presentacion/PG_1/continuar.png"
        class="animated bounceIn slow continuar navigate-next"
        @click="$emit('event', 'Slide 6 - Continuar')"
        :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
      />
      <img
        src="@/assets/presentacion/PG_1/atras.png"
        class="animated bounceIn slow volver navigate-left"
        :style="{ display: slide.boton_volver ? 'block' : 'none' }"
        @click="$emit('event', 'Slide 6 - Volver')"
      />

      <div
        class="recorrido"
        :style="{ display: showRecorrido ? 'block' : 'none' }"
      >
        <img
          src="@/assets/presentacion/PG_6/recorrido1_1.png"
          class="animated fadeIn titulo"
        />
        <img
          src="@/assets/presentacion/PG_6/btn_recor.png"
          class="animated bounceIn slow video_1"
          @click="clickVideo('0')"
        />
      </div>

      <div class="calle" :style="{ display: showCalles ? 'block' : 'none' }">
        <img
          src="@/assets/presentacion/PG_6/titulo_1.png"
          class="animated fadeIn titulo"
        />
        <img
          src="@/assets/presentacion/PG_6/video_1.png"
          class="animated bounceIn slow video_1"
          @click="clickVideo('1')"
        />
        <img
          src="@/assets/presentacion/PG_6/video_2.png"
          class="animated bounceIn slow video_2"
          @click="clickVideo('2')"
        />
        <img
          src="@/assets/presentacion/PG_6/video_3.png"
          class="animated bounceIn slow video_3"
          @click="clickVideo('3')"
        />
      </div>

      <div
        class="interiores"
        :style="{ display: showInteriores ? 'block' : 'none' }"
      >
        <img
          src="@/assets/presentacion/PG_6/titulo_2.png"
          class="animated fadeIn titulo"
        />
        <img
          src="@/assets/presentacion/PG_6/video_4.png"
          class="animated bounceIn slow video_2"
          @click="clickVideo('4')"
        />
      </div>

      <div
        class="certificacion"
        :style="{ display: showCertificacion ? 'block' : 'none' }"
      >
        <img
          src="@/assets/presentacion/PG_6/titulo_3.png"
          class="animated fadeIn titulo"
        />
        <img
          src="@/assets/presentacion/PG_6/video_5.png"
          class="animated bounceIn slow foto_1"
          @click="clickVideo('5')"
        />
      </div>
    </div>
    <modal @close="showModal0 = false" v-if="showModal0">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        src="https://desarrollo.factoriamedia.com/videos_tour/video_3.mp4"
        slot="body"
        @ended="onVideoEnded('0'), (showModal0 = false)"
      ></video>
    </modal>
    <modal @close="showModal1 = false" v-if="showModal1">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_4.mp4"
        slot="body"
        @ended="onVideoEnded('1')"
      ></video>
    </modal>
    <modal @close="showModal2 = false" v-if="showModal2">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_5.mp4"
        slot="body"
        @ended="onVideoEnded('2')"
      ></video>
    </modal>
    <modal @close="showModal3 = false" v-if="showModal3">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_6.mp4"
        slot="body"
        @ended="onVideoEnded('3')"
      ></video>
    </modal>
    <modal @close="showModal4 = false" v-if="showModal4">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_7.mp4"
        slot="body"
        @ended="onVideoEnded('4')"
      ></video>
    </modal>
    <modal @close="showModal7 = false" v-if="showModal7">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_7.mp4"
        slot="body"
        @ended="onVideoEnded('7')"
      ></video>
    </modal>

    <modal @close="showModal5 = false" v-if="showModal5">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/certificacion.mp4"
        slot="body"
        @ended="onVideoEnded('5')"
      ></video>
    </modal>
    <modal @close="showModal6 = false" v-if="showModal6">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://media.istockphoto.com/videos/abstract-technology-background-video-id1264206256"
        slot="body"
        @ended="onVideoEnded('6')"
      ></video>
    </modal>
    <modal @close="showFoto1 = false" v-if="showFoto1">
      <img slot="body" src="@/assets/fotos/foto_8_1.jpg" alt="" />
      <img
        slot="body"
        src="@/assets/presentacion/PG_6/icon.png"
        style="
          position: absolute;
          left: 802px;
          top: 109px;
          width: 315px;
          height: 147px;
        "
      />
    </modal>
    <modal @close="showFoto2 = false" v-if="showFoto2">
      <img slot="body" src="@/assets/fotos/foto_8_2.jpg" alt="" />
    </modal>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import Modal from "../../../components/modal.vue";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      showRecorrido: true,
      showCalles: false,
      showInteriores: false,
      showCertificacion: false,
      showModal0: false,
      showModal1: false,
      showModal2: false,
      showModal3: false,
      showModal4: false,
      showModal5: false,
      showModal6: false,
      showModal7: false,
      showFoto1: false,
      showFoto2: false,
      videoEnded: false,
    };
  },
  methods: {
    clickVideo(video) {
      this.$emit("event", "Slide 6 - Ver Video " + video);
      this["showModal" + video] = true;
    },
    clickFoto(foto) {
      this.$emit("event", "Slide 6 - Ver foto " + foto);
      this["showFoto" + foto] = true;
    },
    onVideoEnded(video) {
      if (video == "mapa") {
        this.videoEnded = true;
      } else {
        this.$emit("event", `Slide 6 - Video ${video} completado`);
      }
    },
    recibeEvento(e) {
      this.videoEnded = false;
    },
    hideAll() {
      this.showRecorrido = false;
      this.showCalles = false;
      this.showInteriores = false;
      this.showCertificacion = false;
      this.showModal1 = false;
      this.showModal2 = false;
      this.showModal3 = false;
      this.showModal4 = false;
      this.showModal5 = false;
      this.showModal6 = false;
      this.showModal7 = false;
      this.showFoto1 = false;
      this.showFoto2 = false;
    },
  },
  watch: {
    slide() {
      this.hideAll();
    },
  },

  components: {
    Modal,
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_6"),
  },
};
</script>

<style lang="scss">
.PG_6 {
  .video {
    position: absolute;
    left: 0;
    top: 0;
  }
  .fix {
    z-index: 10;
  }
  .logo {
    position: absolute;
    left: 125px;
    top: 63px;
  }
  .visitas {
    position: absolute;
    left: 1643px;
    top: 77px;
  }
  .mapa {
    position: absolute;
    left: 105px;
    top: 215px;
  }
  .boton_recorrido {
    position: absolute;
    left: 884px;
    top: 647px;
    cursor: pointer;
  }
  .boton_calles {
    position: absolute;
    left: 1035px;
    top: 580px;
    cursor: pointer;
  }
  .boton_interiores {
    position: absolute;
    left: 725px;
    top: 390px;
    cursor: pointer;
  }
  .boton_certificacion {
    position: absolute;
    left: 1095px;
    top: 325px;
    cursor: pointer;
  }

  .recorrido {
    .titulo {
      position: absolute;
      left: 868px;
      top: 444px;
      pointer-events: none;
    }
    .video_1 {
      position: absolute;
      left: 1251px;
      top: 413px;
      cursor: pointer;
      transition: all 0.5s ease;
    }
    .video_1:hover {
      transform: scale(1.1);
    }
  }

  .calle {
    .titulo {
      position: absolute;
      left: 1015px;
      top: 361px;
      pointer-events: none;
    }
    .video_1 {
      position: absolute;
      left: 255px;
      top: 388px;
      cursor: pointer;
      transition: all 0.5s ease;
    }
    .video_1:hover {
      transform: scale(1.1);
    }
    .video_2 {
      position: absolute;
      left: 496px;
      top: 388px;
      cursor: pointer;
      transition: all 0.5s ease;
    }
    .video_2:hover {
      transform: scale(1.1);
    }
    .video_3 {
      position: absolute;
      left: 734px;
      top: 388px;
      cursor: pointer;
      transition: all 0.5s ease;
    }
    .video_3:hover {
      transform: scale(1.1);
    }
  }
  .interiores {
    .titulo {
      position: absolute;
      left: 705px;
      top: 180px;
      pointer-events: none;
    }
    .video_1 {
      position: absolute;
      left: 1091px;
      top: 124px;
      cursor: pointer;
      transition: all 0.5s ease;
    }
    .video_1:hover {
      transform: scale(1.1);
    }
    .video_2 {
      position: absolute;
      left: 1091px;
      top: 124px;
      cursor: pointer;
      transition: all 0.5s ease;
    }
    .video_2:hover {
      transform: scale(1.1);
    }
    .video_3 {
      position: absolute;
      left: 1160px;
      top: 680px;
      cursor: pointer;
      transition: all 0.5s ease;
    }
    .video_3:hover {
      transform: scale(1.1);
    }
  }

  .certificacion {
    .titulo {
      position: absolute;
      left: 1080px;
      top: 120px;
      pointer-events: none;
    }
    .foto_1 {
      position: absolute;
      left: 801px;
      top: 124px;
      cursor: pointer;
      transition: all 0.5s ease;
    }
    .foto_1:hover {
      transform: scale(1.1);
    }
    .foto_2 {
      position: absolute;
      left: 970px;
      top: 650px;
      cursor: pointer;
      transition: all 0.5s ease;
    }
    .foto_2:hover {
      transform: scale(1.1);
    }
  }

  .IconESP1 {
    position: absolute;
    left: 802px;
    top: 109px;
  }
  .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
  .pulsate-fwd {
    -webkit-animation: pulsate-fwd 1s ease-in-out infinite both;
    animation: pulsate-fwd 1s ease-in-out infinite both;
  }
  @-webkit-keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  @keyframes pulsate-fwd {
    0% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
    50% {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
    100% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}
</style>
