<template>
  <div class="PG_25">
    <img
      src="@/assets/presentacion/PG_12/logo.png"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/PG_12/visita.png"
      class="animated fadeInRight visita"
    />
    <img
      src="@/assets/presentacion/PG_27/text_impacto_social.png"
      class="animated fadeInUp text_impacto_social"
    />
    <img
      src="@/assets/presentacion/PG_27/instrucciones.png"
      class="animated fadeInUp instruccion"
    />
    <img
      src="@/assets/presentacion/PG_27/boton_1.png"
      class="animated bounceIn slow boton_1"
      @click="clickVideo(1)"
      :style="{ display: slide.boton_video_1 ? 'block' : 'none' }"
    />
    <img
      src="@/assets/presentacion/PG_27/boton_2.png"
      class="animated bounceIn slow boton_2"
      @click="clickVideo(2)"
      :style="{ display: slide.boton_video_2 ? 'block' : 'none' }"
    />
    <img
      src="@/assets/presentacion/PG_27/boton_3.png"
      class="animated bounceIn slow boton_3"
      @click="clickVideo(3)"
      :style="{ display: slide.boton_video_3 ? 'block' : 'none' }"
    />


    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 25 - Continuar')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 25 - Volver')"
    />

    <modal @close="showModal1 = false" v-if="showModal1">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/1_recicla_pe.mp4"
        slot="body"
        @ended="onVideoEnded('1')"
      ></video>
    </modal>
    <modal @close="showModal2 = false" v-if="showModal2">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/2_destapando_emprendimiento.mp4"
        slot="body"
        @ended="onVideoEnded('2')"
      ></video>
    </modal>
    <modal @close="showModal3 = false" v-if="showModal3">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/3_uso_del_agua_en_plantas.mp4"
        slot="body"
        @ended="onVideoEnded('3')"
      ></video>
    </modal>
    <modal @close="showModal4 = false" v-if="showModal4">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/4_donaciones_Pucusana_2020.mp4"
        slot="body"
        @ended="onVideoEnded('4')"
      ></video>
    </modal>
   
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import Modal from "../../../components/modal.vue";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      showModal1: false,
      showModal2: false,
      showModal3: false,
      showModal4: false,
    };
  },
  methods: {
    clickVideo(video) {
      this.$emit("event", "Slide 25 - Ver video " + video);
      this["showModal" + video] = true;
    },
    onVideoEnded(video) {
      this.$emit("event", `Slide 25 - Video ${video} completado`);
    },
    hideAll() {
      this.showModal1 = false;
      this.showModal2 = false;
      this.showModal3 = false;
      this.showModal4 = false;
    },
  },
  watch: {
    slide() {
      this.hideAll();
    },
  },
  components: {
    Modal,
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_25"),
  },
};
</script>

<style lang="scss">

  @mixin boton {
    transition: all 0.5s ease;
    cursor: pointer;
    &:hover {
      transform: scale(1.2);
    }
  }

.PG_25 {
  .logo {
    position: absolute;
    left: 135px;
    top: 60px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 668px;
    top: 80px;
  }
  .instruccion {
    position: absolute;
    left: 595px;
    top: 325px;
  }
  .modal-body {
    width: 1920px;
    height: 1080px;
  }
  .titulo-modal {
    position: absolute;
    left: 730px;
    top: 65px;
  }

  .text_impacto_social {
    position: absolute;
    left: 645px;
    top: 80px;
  }

  .instruccion {
    position: absolute;
    left: 770px;
    top: 390px;
  }

  .boton_1 {
    @include boton;
    position: absolute;
    left: 495px;
    top: 505px;
  }
  .boton_2 {
    @include boton;
    position: absolute;
    left: 786px;
    top: 505px;
  }
  .boton_3 {
    @include boton;
    position: absolute;
    left: 1070px;
    top: 505px;
  }
  .boton_4 {
    @include boton;
    position: absolute;
    left: 1260px;
    top: 505px;
  }
  .boton_5 {
    @include boton;
    position: absolute;
    left: 1405px;
    top: 505px;
  }

   .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }


}
</style>
