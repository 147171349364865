<template>
  <div class="PG_21">
    <video
      preload="auto"
      data-autoplay
      loop="true"
      data-src="https://desarrollo.factoriamedia.com/videos_tour/titulo6.mp4"
    ></video>

    <h1
      class="animated bounceIn slower titulo2"
      :style="{ display: slide.respuesta ? 'block' : 'none' }"
    >
      {{ produccion.produccion }}
    </h1>
    <!-- <img
      src="@/assets/presentacion/PG_21/titulo2.png"
      class="animated fadeIn slower titulo2"
      :style="{ display: slide.respuesta ? 'block' : 'none' }"
    /> -->
    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 21 - Continuar')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 21 - Volver')"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      produccion: {},
    };
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_21"),
    produccion: db.collection("presentacion").doc("produccion"),
  },
};
</script>

<style lang="scss">
.PG_21 {
  .logo {
    position: absolute;
    left: 125px;
    top: 60px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 225px;
    top: 50px;
  }
  .titulo2 {
    position: absolute;
    left: 654px;
    top: 728px;
    transform: skewY(-8deg);
    font-size: 138px;
    font-family: "VAG Rounded Std";
    font-weight: 900;
    width: 600px;
    text-align: center;
  }
   .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
