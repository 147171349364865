<template>
  <div class="PG_13">

     <video
      preload="auto"
      data-autoplay
      loop="true"     
      data-src="https://desarrollo.factoriamedia.com/videos_tour/titulo4.mp4"
    ></video>
   
    <img
      src="@/assets/presentacion/PG_8/iniciar.png"
      class="animated fadeInDown iniciar navigate-next"
      @click="$emit('event', 'Slide 13 - Iniciar cuestionario')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 13 - Volver')"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
    };
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_13"),
  },
};
</script>

<style lang="scss">
.PG_13 {
  .logo {
    position: absolute;
    left: 125px;
    top: 60px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 225px;
    top: 60px;
  }
  .titulo2 {
    position: absolute;
    left: 721px;
    top: 760px;
  }
  // .iniciar {
  //   position: absolute;
  //   left: 885px;
  //   top: 938px;
  //   cursor: pointer;
  // transition: all 0.5s ease;
  // }
  // .iniciar:hover{
  //   transform: scale(1.2);
  // }
    .iniciar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .iniciar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
