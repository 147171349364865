<template>
  <div class="PG_4">
     <img
      src="@/assets/presentacion/logoWhi.svg"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/logoDer.svg"
      class="animated fadeInRight logoD"
    />
    <!-- <img
      src="@/assets/presentacion/PG_2/visita.png"
      class="animated fadeInDown visita"
    /> -->
    <img
      src="@/assets/presentacion/PG_4/titulo.png"
      class="animated bounceIn titulo"
    />

    <div class="temas animated fadeIn">
      <div
        class="item"
        id="1"
        :class="
          selected.length > 0
            ? selected.includes('1')
              ? 'selected'
              : 'unselected'
            : ''
        "
        @click="toggle('1') & $emit('event', 'Slide 4 - Seleccionado Opción 1' ) "
      >
        <img src="@/assets/presentacion/PG_4/tema_1.png" />
      </div>
      <div
        class="item"
        id="2"
        :class="
          selected.length > 0
            ? selected.includes('2')
              ? 'selected'
              : 'unselected'
            : ''
        "
        @click="toggle('2') & $emit('event', 'Slide 4 - Seleccionado Opción 2' ) "
      >
        <img src="@/assets/presentacion/PG_4/tema_2.png" />
      </div>
      <div
        class="item"
        id="3"
        :class="
          selected.length > 0
            ? selected.includes('3')
              ? 'selected'
              : 'unselected'
            : ''
        "
        @click="toggle('3') & $emit('event', 'Slide 4 - Seleccionado Opción 3' ) "
      >
        <img src="@/assets/presentacion/PG_4/tema_3.png" />
      </div>
      <div
        class="item"
        id="4"
        :class="
          selected.length > 0
            ? selected.includes('4')
              ? 'selected'
              : 'unselected'
            : ''
        "
        @click="toggle('4') & $emit('event', 'Slide 4 - Seleccionado Opción 4' ) "
      >
        <img src="@/assets/presentacion/PG_4/tema_4.png" />
      </div>
    </div>
    <img
      src="@/assets/presentacion/PG_4/escribenos.png"
      class="animated bounceIn escribenos"
    />
    <el-input
      placeholder="Escribe aquí..."
      v-model="intereses"
      class="seleccionar animated fadeIn"
      @change="$emit('event', 'Slide 4 - Escribiendo intereses' )"
    >
    </el-input>
    <img
      src="@/assets/presentacion/PG_4/finalizar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 4 - Finalizar') && saveCuestionary()"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
        <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 4 - Volver')"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      selected: [],
      intereses: "",
      time: 0,
    };
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_4"),
  },
  mounted() {
    this.time = Date.now();
  },
  methods: {
    toggle(id) {
      if (this.selected.includes(id)) {
        this.selected = this.selected.filter((item) => item !== id);
      } else {
        this.selected.push(id);
      }
    },
    async saveCuestionary() {
      var user = firebase.auth().currentUser;
      var userData = await db.collection("users").doc(user.uid).get();

      var date = new Date();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var fecha = month + "" + day + "" + year + "";

      await db
        .collection("cuestionarios")
        .doc(fecha)
        .collection("cuestionario_1")
        .doc(user.uid)
        .set({
          intereses: this.intereses,
          duracion: Date.now() - this.time,
          seleccionado: this.selected,
          nombre: userData.data().nombre,
        });
    },
  },
};
</script>

<style lang="scss">
.PG_4 {
  .logo {
    position: absolute;
    left : 126px;
  top : 62px;
  width : 207px;
  height : 134px;
  }
  .logoD {
    position: absolute;
     left : 1642px;
  top : 76px;
  width : 193px;
  height : 68px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 525px;
    top: 210px;
  }

  .temas {
    position: absolute;
    left: 550px;
    top: 355px;
    width: 840px;
    height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .item {
      cursor: pointer;
      transition: all 200ms ease-out;
      background-color: white;
      width: 200px;
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
    transition: all 0.5s ease;
    z-index: 0;
  }
  .item:hover {
   transform: scale(1.1);
   z-index: 1;
  }
    .item.unselected {
      background-color: rgba(0, 0, 0, 0.6);
      visibility: unset;
      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(268deg)
          brightness(105%) contrast(103%);
      }
    }

    .item.selected {
      background-color: #7c3a11;
      transform: scale(1.1);
      visibility: unset;
      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(268deg)
          brightness(105%) contrast(103%);
      }
    }
  }

  .escribenos {
    position: absolute;
    left: 696px;
    top: 735px;
  }

  .seleccionar {
    position: absolute;
    left: 580px;
    top: 775px;
    width: 780px;
    input {
      border-radius: 25px;
      font-size: 24px;
          padding: 30px;
    }
  }

 .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
