<template>
  <div class="saludo">
    <el-form>
      <el-form-item label="Cantidad de Producción">
        <el-input v-model="produccion.produccion" @change="changeProduccion"></el-input>
      </el-form-item>
    </el-form>
    <el-form>
      <el-form-item label="Cantidad de Plantas">
        <el-input v-model="plantas.plantas" @change="changePlantas"></el-input>
      </el-form-item>
    </el-form>
    <!-- <iframe
      class="presentacion"
      src="https://tourvirtualacl.com/presentacion"
      frameborder="0"
    ></iframe> -->
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import PG1 from "../presentacion/slides/pg_1.vue";
const db = firebase.firestore();
export default {
  components: {
    PG1,
  },
  data() {
    return {
      produccion: {},
      plantas: {},
    };
  },
  firestore: {
    produccion: db.collection("presentacion").doc("produccion"),
    plantas: db.collection("presentacion").doc("plantas"),
  },
  methods: {
    changeProduccion() {
      db.collection("presentacion").doc("produccion").update({
        produccion: this.produccion.produccion,
      });
    },
    changePlantas() {
      db.collection("presentacion").doc("plantas").update({
        plantas: this.plantas.plantas,
      });
    },
  },
};
</script>

<style lang="scss">
.saludo {
  iframe {
    width: 100%;
    height: 100vh;
  }
}
</style>
