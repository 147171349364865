<template>
  <div class="PG_26">
    <video
      preload="auto"
      data-autoplay
      loop="true"
      data-src="https://desarrollo.factoriamedia.com/videos_tour/titulo8.mp4"
    ></video>
    <img
      src="@/assets/presentacion/PG_4/finalizar.png"
      class="animated fadeInDown continuar navigate-next"      
       @click="logout"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 26 - Volver')"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
import router from "../../../router";

var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
    };
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_26"),
  },
  methods: {
   logout() {
      firebase.auth().signOut();
      router.push("/");
    },
  },
};
</script>

<style lang="scss">
.PG_26 {
  .logo {
    position: absolute;
    left: 125px;
    top: 60px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 200px;
    top: 200px;
  }

  // .continuar {
  //   position: absolute;
  //   left: 844px;
  //   top: 930px;
  //   cursor: pointer;
  //   transition: all 0.5s ease;
  // }
  // .continuar:hover {
  //   transform: scale(1.2);
  // }
    .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
