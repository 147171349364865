<template>
  <div>
    <el-tabs type="border-card">
      <el-tab-pane label="Visitantes">
        <visitantes></visitantes>
      </el-tab-pane>
      <el-tab-pane label="Cantidades"
        ><bienvenida></bienvenida
      ></el-tab-pane>
      <el-tab-pane label="Visita"><visita></visita></el-tab-pane>
      <el-tab-pane label="Cuestionario"
        ><cuestionario></cuestionario
      ></el-tab-pane>
      <el-tab-pane label="Usuarios"><usuarios></usuarios></el-tab-pane>
    </el-tabs>
    <el-button type="primary" class="logout" @click="logout"
      >Cerrar sesión</el-button
    >
  </div>
</template>

<script>
import Visita from "./visita";
import Visitantes from "./visitantes.vue";
import Bienvenida from "./bienvenida.vue";
import Cuestionario from "./cuestionarios.vue";
import Usuarios from "./usuarios.vue";
import firebase from "firebase/app";
import "firebase/auth";
import router from "../../router";

export default {
  components: {
    Visita,
    Visitantes,
    Bienvenida,
    Cuestionario,
    Usuarios,
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {
    logout() {
      firebase.auth().signOut();
      router.push("/");
    },
  },
};
</script>

<style lang="scss">
.container {
  .el-tabs__content {
    padding: 0;
  }
}

html {
  overflow: unset !important;
}
body {
  overflow: unset !important;
}

.logout {
  position: absolute;
  right: 0;
  top: 0;
}
</style>
