<template>
  <div class="PG_10">
    <img
      src="@/assets/presentacion/PG_6/logo.png"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/PG_6/visitas.png"
      class="animated fadeInRight visitas"
    />
    <img
      src="@/assets/presentacion/PG_6/mapa.png"
      class="mapa"
    />
    <img
      src="@/assets/presentacion/PG_7/preparacion.png"
      class="animated bounceIn slow preparacion"
      :style="{
        display: slide.preparacion ? 'block' : 'none',
      }"
    />
    <!-- <img
      src="@/assets/presentacion/PG_7/proceso_distribucion.png"
      class="animated bounceIn slow proceso_distribucion"
      :style="{ display: slide.proceso_distribucion ? 'block' : 'none' }"
    />
    <img
      src="@/assets/presentacion/PG_7/proceso_embotellado.png"
      class="animated bounceIn slow proceso_embotellado"
      :style="{ display: slide.proceso_embotellado ? 'block' : 'none' }"
    /> -->

    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 10 - Continuar')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 10 - Volver')"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import Modal from "../../../components/modal.vue";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
    };
  },

  components: {
    Modal,
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_10"),
  },
};
</script>

<style lang="scss">
.PG_10 {
  .logo {
    position: absolute;
    left: 125px;
    top: 60px;
  }
  .visitas {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .mapa {
    position: absolute;
    left: 105px;
    top: 215px;
  }
  .preparacion {
    position: absolute;
    left: 1020px;
    top: 500px;
    // cursor: pointer;
  }
  .proceso_distribucion {
    position: absolute;
    left: 880px;
    top: 115px;
  }
  .proceso_embotellado {
    position: absolute;
    left: 585px;
    top: 430px;
  }
  .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
