<template>
  <div class="PG_20">

    <img
      src="@/assets/presentacion/PG_12/logo.png"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/PG_12/visita.png"
      class="animated fadeInRight visita"
    />
    <img
      src="@/assets/presentacion/PG_19/titulo2.png"
      class="animated fadeInUp titulo"
    />
    <img
      src="@/assets/presentacion/PG_17/instruccion.png"
      class="animated fadeInDown instruccion"
    />

    <img
      src="@/assets/presentacion/PG_20/off_lineas.png"
      class="animated bounceIn slow boton_lineas"
      :style="{ display: slide.boton_1_lineas ? 'none' : 'block' }"
    />
    <div
      class="lineas"
      :style="{ display: slide.boton_1_lineas ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_20/text_lineas.png"
        class="animated bounceIn slow boton_lineas"
        @click="clickVideo('1')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_20/off_preforma.png"
      class="animated bounceIn slow boton_preforma"
      :style="{ display: slide.boton_2_preforma ? 'none' : 'block' }"
    />
    <div
      class="preforma"
      :style="{ display: slide.boton_2_preforma ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_20/text_preforma.png"
        class="animated bounceIn slow boton_preforma"
        @click="clickVideo('2')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_20/off_llenadora.png"
      class="animated bounceIn slow boton_llenadora"
      :style="{ display: slide.boton_3_llenadora ? 'none' : 'block' }"
    />
    <div
      class="llenadora"
      :style="{ display: slide.boton_3_llenadora ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_20/text_llenadora.png"
        class="animated bounceIn slow boton_llenadora"
        @click="clickVideo('3')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_20/off_inspector_electronico.png"
      class="animated bounceIn slow boton_inspector_electronico"
      :style="{
        display: slide.boton_4_inspector ? 'none' : 'block',
      }"
    />
    <div
      class="inspector_electronico"
      :style="{
        display: slide.boton_4_inspector ? 'block' : 'none',
      }"
    >
      <img
        src="@/assets/presentacion/PG_20/text_inspector_electronico.png"
        class="animated bounceIn slow boton_inspector_electronico"
        @click="clickVideo('4')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_20/off_etiquetadora.png"
      class="animated bounceIn slow boton_etiquetadora"
      :style="{ display: slide.boton_5_etiquetadora ? 'none' : 'block' }"
    />
    <div
      class="etiquetadora"
      :style="{ display: slide.boton_5_etiquetadora ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_20/text_etiquetadora.png"
        class="animated bounceIn slow boton_etiquetadora"
        @click="clickVideo('5')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_20/off_empacadora.png"
      class="animated bounceIn slow boton_inspector_llenas"
      :style="{ display: slide.boton_6_inspector_llenas ? 'none' : 'block' }"
    />
    <div
      class="inspector_llenas"
      :style="{ display: slide.boton_6_inspector_llenas ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_20/text_empacadora.png"
        class="animated bounceIn slow boton_inspector_llenas"
        @click="clickVideo('6')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_20/off_paletizadora.png"
      class="animated bounceIn slow boton_paletizadora"
      :style="{ display: slide.boton_7_paletizadora ? 'none' : 'block' }"
    />
    <div
      class="paletizadora"
      :style="{ display: slide.boton_7_paletizadora ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_20/text_paletizadora.png"
        class="animated bounceIn slow boton_paletizadora"
        @click="clickVideo('7')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 20 - Continuar')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 10 - Volver')"
    />

    <modal @close="showModal1 = false" v-if="showModal1">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_23.mp4"
        slot="body"
        @ended="onVideoEnded('1')"
      ></video>
    </modal>
    <modal @close="showModal2 = false" v-if="showModal2">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_24.mp4"
        slot="body"
        @ended="onVideoEnded('2')"
      ></video>
    </modal>
    <modal @close="showModal3 = false" v-if="showModal3">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_25.mp4"
        slot="body"
        @ended="onVideoEnded('3')"
      ></video>
    </modal>
    <modal @close="showModal4 = false" v-if="showModal4">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_26.mp4"
        slot="body"
        @ended="onVideoEnded('4')"
      ></video>
    </modal>
    <modal @close="showModal5 = false" v-if="showModal5">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_27.mp4"
        slot="body"
        @ended="onVideoEnded('5')"
      ></video>
    </modal>
    <modal @close="showModal6 = false" v-if="showModal6">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_28.mp4"
        slot="body"
        @ended="onVideoEnded('6')"
      ></video>
    </modal>
    <modal @close="showModal7 = false" v-if="showModal7">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_29.mp4"
        slot="body"
        @ended="onVideoEnded('7')"
      ></video>
    </modal>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import Modal from "../../../components/modal.vue";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      showModal1: false,
      showModal2: false,
      showModal3: false,
      showModal4: false,
      showModal5: false,
      showModal6: false,
      showModal7: false,
    };
  },
  methods: {
    clickVideo(video) {
      this.$emit("event", "Slide 20 - Ver Video " + video);
      this["showModal" + video] = true;
    },
    onVideoEnded(video) {
      this.$emit("event", `Slide 20 - Video ${video} completado`);
    },
    hideAll() {
      this.showModal1 = false;
      this.showModal2 = false;
      this.showModal3 = false;
      this.showModal4 = false;
      this.showModal5 = false;
      this.showModal6 = false;
      this.showModal7 = false;
    },
  },
  watch: {
    slide() {
      this.hideAll();
    },
  },
  components: {
    Modal,
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_20"),
  },
};
</script>

<style lang="scss">
.PG_20 {
  //  .backVid{
  //   position: absolute;

  // }
  .logo {
    position: absolute;
    left: 135px;
    top: 60px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 642px;
    top: 80px;
  }
  .instruccion {
    position: absolute;
    left: 773px;
    top: 361px;
  }

  .boton_lineas {
    position: absolute;
    left: 205px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_lineas:hover {
    transform: scale(1.05);
  }
  .text_lineas {
    position: absolute;
    left: 205px;
    top: 519px;
  }

  .boton_preforma {
    position: absolute;
    left: 425px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_preforma:hover {
    transform: scale(1.05);
  }
  .text_preforma {
    position: absolute;
    left: 425px;
    top: 519px;
  }

  .boton_llenadora {
    position: absolute;
    left: 643px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_llenadora:hover {
    transform: scale(1.05);
  }
  .text_llenadora {
    position: absolute;
    left: 643px;
    top: 519px;
  }

  .boton_inspector_electronico {
    position: absolute;
    left: 860px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_inspector_electronico:hover {
    transform: scale(1.05);
  }
  .text_inspector_electronico {
    position: absolute;
    left: 860px;
    top: 519px;
  }

  .boton_etiquetadora {
    position: absolute;
    left: 1081px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_etiquetadora:hover {
    transform: scale(1.05);
  }
  .text_etiquetadora {
    position: absolute;
    left: 1081px;
    top: 519px;
  }

  .boton_inspector_llenas {
    position: absolute;
    left: 1298px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_inspector_llenas:hover {
    transform: scale(1.05);
  }
  .text_inspector_llenas {
    position: absolute;
    left: 1298px;
    top: 519px;
  }

  .boton_paletizadora {
    position: absolute;
    left: 1515px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_paletizadora:hover {
    transform: scale(1.05);
  }
  .text_paletizadora {
    position: absolute;
    left: 1515px;
    top: 519px;
  }

  .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
