var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PG_4"},[_c('img',{staticClass:"animated fadeInLeft logo",attrs:{"src":require("@/assets/presentacion/logoWhi.svg")}}),_c('img',{staticClass:"animated fadeInRight logoD",attrs:{"src":require("@/assets/presentacion/logoDer.svg")}}),_c('img',{staticClass:"animated bounceIn titulo",attrs:{"src":require("@/assets/presentacion/PG_4/titulo.png")}}),_c('div',{staticClass:"temas animated fadeIn"},[_c('div',{staticClass:"item",class:_vm.selected.length > 0
          ? _vm.selected.includes('1')
            ? 'selected'
            : 'unselected'
          : '',attrs:{"id":"1"},on:{"click":function($event){_vm.toggle('1') & _vm.$emit('event', 'Slide 4 - Seleccionado Opción 1' )}}},[_c('img',{attrs:{"src":require("@/assets/presentacion/PG_4/tema_1.png")}})]),_c('div',{staticClass:"item",class:_vm.selected.length > 0
          ? _vm.selected.includes('2')
            ? 'selected'
            : 'unselected'
          : '',attrs:{"id":"2"},on:{"click":function($event){_vm.toggle('2') & _vm.$emit('event', 'Slide 4 - Seleccionado Opción 2' )}}},[_c('img',{attrs:{"src":require("@/assets/presentacion/PG_4/tema_2.png")}})]),_c('div',{staticClass:"item",class:_vm.selected.length > 0
          ? _vm.selected.includes('3')
            ? 'selected'
            : 'unselected'
          : '',attrs:{"id":"3"},on:{"click":function($event){_vm.toggle('3') & _vm.$emit('event', 'Slide 4 - Seleccionado Opción 3' )}}},[_c('img',{attrs:{"src":require("@/assets/presentacion/PG_4/tema_3.png")}})]),_c('div',{staticClass:"item",class:_vm.selected.length > 0
          ? _vm.selected.includes('4')
            ? 'selected'
            : 'unselected'
          : '',attrs:{"id":"4"},on:{"click":function($event){_vm.toggle('4') & _vm.$emit('event', 'Slide 4 - Seleccionado Opción 4' )}}},[_c('img',{attrs:{"src":require("@/assets/presentacion/PG_4/tema_4.png")}})])]),_c('img',{staticClass:"animated bounceIn escribenos",attrs:{"src":require("@/assets/presentacion/PG_4/escribenos.png")}}),_c('el-input',{staticClass:"seleccionar animated fadeIn",attrs:{"placeholder":"Escribe aquí..."},on:{"change":function($event){return _vm.$emit('event', 'Slide 4 - Escribiendo intereses' )}},model:{value:(_vm.intereses),callback:function ($$v) {_vm.intereses=$$v},expression:"intereses"}}),_c('img',{staticClass:"animated bounceIn slow continuar navigate-next",style:({ display: _vm.slide.boton_continuar ? 'block' : 'none' }),attrs:{"src":require("@/assets/presentacion/PG_4/finalizar.png")},on:{"click":function($event){_vm.$emit('event', 'Slide 4 - Finalizar') && _vm.saveCuestionary()}}}),_c('img',{staticClass:"animated bounceIn slow volver navigate-left",style:({ display: _vm.slide.boton_volver ? 'block' : 'none' }),attrs:{"src":require("@/assets/presentacion/PG_1/atras.png")},on:{"click":function($event){return _vm.$emit('event', 'Slide 4 - Volver')}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }