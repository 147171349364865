<template>
  <div class="PG_14">
    <img
      src="@/assets/presentacion/PG_2/logo.png"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/PG_2/visita.png"
      class="animated fadeInRight visita"
    />
    <img
      src="@/assets/presentacion/PG_14/titulo.png"
      class="animated fadeInUp titulo"
    />
    <img
      src="@/assets/presentacion/PG_14/titulo2.png"
      class="animated fadeInDown titulo2"
    />

    <div class="temas animated bounceIn slow">
      <div
        class="item"
        id="1"
        :class="
          selected.length > 0
            ? selected.includes('1')
              ? 'selected'
              : 'unselected'
            : ''
        "
        @click="
          toggle('1') & $emit('event', 'Slide 14 - Seleccionado Opción 1')
        "
      >
        <img src="@/assets/presentacion/PG_14/opt_1.png" />
      </div>

      <div
        class="item"
        id="2"
        :class="
          selected.length > 0
            ? selected.includes('2')
              ? 'selected'
              : 'unselected'
            : ''
        "
        @click="
          toggle('2') & $emit('event', 'Slide 14 - Seleccionado Opción 2')
        "
      >
        <img src="@/assets/presentacion/PG_14/opt_2.png" />
      </div>

      <div
        class="item"
        id="3"
        :class="
          selected.length > 0
            ? selected.includes('3')
              ? 'selected'
              : 'unselected'
            : ''
        "
        @click="
          toggle('3') & $emit('event', 'Slide 14 - Seleccionado Opción 3')
        "
      >
        <img src="@/assets/presentacion/PG_14/opt_3.png" />
      </div>

      <div
        class="item"
        id="4"
        :class="
          selected.length > 0
            ? selected.includes('4')
              ? 'selected'
              : 'unselected'
            : ''
        "
        @click="
          toggle('4') & $emit('event', 'Slide 14 - Seleccionado Opción 4')
        "
      >
        <img src="@/assets/presentacion/PG_14/opt_4.png" />
      </div>

      <div
        class="item"
        id="5"
        :class="
          selected.length > 0
            ? selected.includes('5')
              ? 'selected'
              : 'unselected'
            : ''
        "
        @click="
          toggle('5') & $emit('event', 'Slide 14 - Seleccionado Opción 5')
        "
      >
        <img src="@/assets/presentacion/PG_14/opt_5.png" />
      </div>

      <div
        class="item"
        id="6"
        :class="
          selected.length > 0
            ? selected.includes('6')
              ? 'selected'
              : 'unselected'
            : ''
        "
        @click="
          toggle('6') & $emit('event', 'Slide 14 - Seleccionado Opción 6')
        "
      >
        <img src="@/assets/presentacion/PG_14/opt_6.png" />
      </div>

      <div
        class="item"
        id="7"
        :class="
          selected.length > 0
            ? selected.includes('7')
              ? 'selected'
              : 'unselected'
            : ''
        "
        @click="
          toggle('7') & $emit('event', 'Slide 14 - Seleccionado Opción 7')
        "
      >
        <img src="@/assets/presentacion/PG_14/opt_7.png" />
      </div>
    </div>
    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 14 - Finalizar') && saveCuestionary()"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 14 - Volver')"
    />
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      selected: [],
      intereses: "",
      time: 0,
    };
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_14"),
  },
  mounted() {
    this.time = Date.now();
  },
  methods: {
    toggle(id) {
      if (this.selected.includes(id)) {
        this.selected = this.selected.filter((item) => item !== id);
      } else {
        this.selected.push(id);
      }
    },
    async saveCuestionary() {
      var user = firebase.auth().currentUser;
      var userData = await db.collection("users").doc(user.uid).get();

      var date = new Date();
      var day = date.getDate();
      var month = date.getMonth() + 1;
      var year = date.getFullYear();
      var fecha = month + "" + day + "" + year + "";

      await db
        .collection("cuestionarios")
        .doc(fecha)
        .collection("cuestionario_3")
        .doc(user.uid)
        .set({
          duracion: Date.now() - this.time,
          seleccionado: this.selected,
          nombre: userData.data().nombre,
        });
    },
  },
};
</script>

<style lang="scss">
.PG_14 {
  .logo {
    position: absolute;
    left: 125px;
    top: 60px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 525px;
    top: 260px;
  }
  .titulo2 {
    position: absolute;
    left: 494px;
    top: 382px;
  }

  .temas {
    position: absolute;
    left: 14px;
    top: 480px;
    width: 1834px;
    height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    .item {
      cursor: pointer;
      transition: all 200ms ease-out;
      background-color: white;
      width: 300px;
      height: 300px;
      margin-left: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 20px;
      padding: 0px 40px 0px 44px;
     transition: all 0.5s ease;
  }
  .item:hover{
    transform: scale(1.1);
  }
    .item.unselected {
      background-color: rgba(56, 56, 56, 0.6);
      visibility: unset;
      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(268deg)
          brightness(105%) contrast(103%);
      }
    }

    .item.selected {
      background-color: #e41e2a;
      transform: scale(1.2);
      visibility: unset;
      img {
        filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(268deg)
          brightness(105%) contrast(103%);
      }
    }
  }

  .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
