<template>
  <div class="PG_11">
    <img
      src="@/assets/presentacion/PG_2/logo.png"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/PG_2/visita.png"
      class="animated fadeInRight visita"
    />
    <img
      src="@/assets/presentacion/PG_10/titulo.png"
      class="animated fadeInDown titulo"
    />

    <img
      src="@/assets/presentacion/PG_10/video_ingredientes.png"
      class="animated fadeInDown v_ingredientes"
    />

    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 11 - Continuar')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 11 - Volver')"
    />

    <div class="bebidas">
      <img
        src="@/assets/presentacion/PG_10/haz_clic.png"
        class="animated fadeInDown text_haz_clic"
      />
      <img
        src="@/assets/presentacion/PG_10/video_1.png"
        class="animated zoomIn video_1"
        :style="{ display: slide.video_1 ? 'block' : 'none' }"
        @click="clickVideo('1')"
      />
      <img
        src="@/assets/presentacion/PG_10/video_2.png"
        class="animated zoomIn video_2"
        :style="{ display: slide.video_2 ? 'block' : 'none' }"
        @click="clickVideo('2')"
      />
      <img
        src="@/assets/presentacion/PG_10/video_3.png"
        class="animated zoomIn video_3"
        :style="{ display: slide.video_3 ? 'block' : 'none' }"
        @click="clickVideo('3')"
      />
      <img
        src="@/assets/presentacion/PG_10/video_4.png"
        class="animated zoomIn video_4"
        :style="{ display: slide.video_4 ? 'block' : 'none' }"
        @click="clickVideo('4')"
      />
    </div>
    <modal @close="showModal1 = false" v-if="showModal1">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/inka_cola.mp4"
        slot="body"
        @ended="onVideoEnded('1')"
      ></video>
    </modal>
    <modal @close="showModal2 = false" v-if="showModal2">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/coca_cola.mp4"
        slot="body"
        @ended="onVideoEnded('2')"
      ></video>
    </modal>
    <modal @close="showModal3 = false" v-if="showModal3">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/fanta.mp4"
        slot="body"
        @ended="onVideoEnded('3')"
      ></video>
    </modal>
    <modal @close="showModal4 = false" v-if="showModal4">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/sprite.mp4"
        slot="body"
        @ended="onVideoEnded('4')"
      ></video>
    </modal>
    <modal @close="showModal5 = false" v-if="showModal5">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://media.istockphoto.com/videos/abstract-technology-background-video-id1264206256"
        slot="body"
        @ended="onVideoEnded('5')"
      ></video>
    </modal>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import Modal from "../../../components/modal.vue";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      showModal1: false,
      showModal2: false,
      showModal3: false,
      showModal4: false,
      showModal5: false,
    };
  },
  methods: {
    clickVideo(video) {
      this.$emit("event", "Slide 11 - Ver Video " + video);
      this["showModal" + video] = true;
    },
    onVideoEnded(video) {
      this.$emit("event", `Slide 11 - Video ${video} completado`);
    },
    hideAll() {
      this.showModal1 = false;
      this.showModal2 = false;
      this.showModal3 = false;
      this.showModal4 = false;
      this.showModal5 = false;
    },
  },
  watch: {
    slide() {
      this.hideAll();
    },
  },
  components: {
    Modal,
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_11"),
  },
};
</script>

<style lang="scss">
.PG_11 {
  .logo {
    position: absolute;
    left: 125px;
    top: 60px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 517px;
    top: 80px;
  }
  .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
  .video_5 {
    position: absolute;
    left: 286px;
    top: 425px;
    cursor: pointer;
  }
  .v_ingredientes {
    position: absolute;
    left: 724px;
    top: 273px;
  }
  .text_haz_clic {
    position: absolute;
    left: 769px;
    top: 402px;
  }
  .video_1 {
    position: absolute;
    left: 396px;
    top: 505px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .video_1:hover {
    transform: scale(1.1);
  }
  .video_2 {
    position: absolute;
    left: 686px;
    top: 505px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .video_2:hover {
    transform: scale(1.1);
  }
  .video_3 {
    position: absolute;
    left: 971px;
    top: 505px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .video_3:hover {
    transform: scale(1.1);
  }
  .video_4 {
    position: absolute;
    left: 1261px;
    top: 505px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .video_4:hover {
    transform: scale(1.1);
  }
}
</style>
