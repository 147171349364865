<template>
  <div class="PG_18">   

    <img
      src="@/assets/presentacion/PG_12/logo.png"
      class="animated fadeInLeft logo"
    />
    <img
      src="@/assets/presentacion/PG_12/visita.png"
      class="animated fadeInRight visita"
    />
    <img
      src="@/assets/presentacion/PG_17/titulo.png"
      class="animated fadeInUp titulo"
    />
    <img
      src="@/assets/presentacion/PG_17/instruccion.png"
      class="animated fadeInDown instruccion"
    />

    <img
      src="@/assets/presentacion/PG_17/off_despaletizadora.png"
      class="animated bounceIn slow boton_despaletizadora"
      :style="{ display: slide.boton_1_despaletizadora ? 'none' : 'block' }"
    />
    <div
      class="despaletizadora"
      :style="{ display: slide.boton_1_despaletizadora ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_17/text_despaletizadora.png"
        class="animated bounceIn slow boton_despaletizadora"
        @click="clickVideo('1')"
      />
    </div>


    <img
      src="@/assets/presentacion/PG_17/off_lavadora_cajas.png"
      class="animated bounceIn slow boton_lavadora_cajas"
      :style="{ display: slide.boton_2_lavadora ? 'none' : 'block' }"
    />
    <div
      class="lavadora_cajas"
      :style="{ display: slide.boton_2_lavadora ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_17/text_lavadora_cajas.png"
        class="animated bounceIn slow boton_lavadora_cajas"
        @click="clickVideo('3')"
      />
    </div>

    
    <img
      src="@/assets/presentacion/PG_17/off_lavadora_botellas.png"
      class="animated bounceIn slow boton_lavado_botellas"
      :style="{ display: slide.boton_3_lavado ? 'none' : 'block' }"
    />
    <div
      class="lavado_botellas"
      :style="{ display: slide.boton_3_lavado ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_17/text_lavadora_botellas.png"
        class="animated bounceIn slow boton_lavado_botellas"
        @click="clickVideo('2')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_17/off_inspector_vacias.png"
      class="animated bounceIn slow boton_inspector_vacias"
      :style="{ display: slide.boton_4_inspector ? 'none' : 'block' }"
    />
    <div
      class="inspector_vacias"
      :style="{ display: slide.boton_4_inspector ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_17/text_inspector_vacias.png"
        class="animated bounceIn slow boton_inspector_vacias"
        @click="clickVideo('4')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_17/off_llenadora.png"
      class="animated bounceIn slow boton_llenadora"
      :style="{ display: slide.boton_5_llenadora ? 'none' : 'block' }"
    />
    <div
      class="llenadora"
      :style="{ display: slide.boton_5_llenadora ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_17/text_llenadora.png"
        class="animated bounceIn slow boton_llenadora"
        @click="clickVideo('5')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_17/off_inspector_llenas.png"
      class="animated bounceIn slow boton_inspector_llenas"
      :style="{ display: slide.boton_6_inspector_llenas ? 'none' : 'block' }"
    />
    <div
      class="inspector_llenas"
      :style="{ display: slide.boton_6_inspector_llenas ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_17/text_inspector_llenas.png"
        class="animated bounceIn slow boton_inspector_llenas"
        @click="clickVideo('6')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_17/off_encajonadora.png"
      class="animated bounceIn slow boton_encajonadora"
      :style="{ display: slide.boton_7_encajonadora ? 'none' : 'block' }"
    />
    <div
      class="encajonadora"
      :style="{ display: slide.boton_7_encajonadora ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_17/text_encajonadora.png"
        class="animated bounceIn slow boton_encajonadora"
        @click="clickVideo('7')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_17/off_paletizadora.png"
      class="animated bounceIn slow boton_paletizadora"
      :style="{ display: slide.boton_8_paletizadora ? 'none' : 'block' }"
    />
    <div
      class="paletizadora"
      :style="{ display: slide.boton_8_paletizadora ? 'block' : 'none' }"
    >
      <img
        src="@/assets/presentacion/PG_17/text_paletizadora.png"
        class="animated bounceIn slow boton_paletizadora"
        @click="clickVideo('8')"
      />
    </div>

    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 18 - Continuar')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
     <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 18 - Volver')"
    />

    <modal @close="showModal1 = false" v-if="showModal1">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_14.mp4"
        slot="body"
        @ended="onVideoEnded('1')"
      ></video>
    </modal>
    <modal @close="showModal2 = false" v-if="showModal2">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_16.mp4"
        slot="body"
        @ended="onVideoEnded('2')"
      ></video>
    </modal>
    <modal @close="showModal3 = false" v-if="showModal3">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_15.mp4"
        slot="body"
        @ended="onVideoEnded('3')"
      ></video>
    </modal>
    <modal @close="showModal4 = false" v-if="showModal4">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_17.mp4"
        slot="body"
        @ended="onVideoEnded('4')"
      ></video>
    </modal>
    <modal @close="showModal5 = false" v-if="showModal5">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_18.mp4"
        slot="body"
        @ended="onVideoEnded('5')"
      ></video>
    </modal>
    <modal @close="showModal6 = false" v-if="showModal6">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_19.mp4"
        slot="body"
        @ended="onVideoEnded('6')"
      ></video>
    </modal>
    <modal @close="showModal7 = false" v-if="showModal7">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_20.mp4"
        slot="body"
        @ended="onVideoEnded('7')"
      ></video>
    </modal>
    <modal @close="showModal8 = false" v-if="showModal8">
      <video
        preload="auto"
        class="video-modal"
        autoplay
        controls
        src="https://desarrollo.factoriamedia.com/videos_tour/video_21.mp4"
        slot="body"
        @ended="onVideoEnded('8')"
      ></video>
    </modal>
  </div>
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
import Modal from "../../../components/modal.vue";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
      showModal1: false,
      showModal2: false,
      showModal3: false,
      showModal4: false,
      showModal5: false,
      showModal6: false,
      showModal7: false,
      showModal8: false,
    };
  },
  methods: {
    clickVideo(video) {
      this.$emit("event", "Slide 18 - Ver Video " + video);
      this["showModal" + video] = true;
    },
    onVideoEnded(video) {
      this.$emit("event", `Slide 18 - Video ${video} completado`);
    },
    hideAll() {
      this.showModal1 = false;
      this.showModal2 = false;
      this.showModal3 = false;
      this.showModal4 = false;
      this.showModal5 = false;
      this.showModal6 = false;
      this.showModal7 = false;
      this.showModal8 = false;
    },
  },
  watch: {
    slide() {
      this.hideAll();
    },
  },
  components: {
    Modal,
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_18"),
  },
};
</script>

<style lang="scss">
.PG_18 {
  // .backVid{
  //   position: absolute;
  // }
  .logo {
    position: absolute;
    left: 135px;
    top: 60px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 700px;
    top: 80px;
  }
  .instruccion {
    position: absolute;
    left: 761px;
    top: 383px;
  }

  .boton_despaletizadora {
    position: absolute;
    left: 96px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_despaletizadora:hover {
    transform: scale(1.05);
  }
  .text_despaletizadora {
    position: absolute;
    left: 96px;
    top: 519px;
  }

  .boton_lavado_botellas {
    position: absolute;
    left: 534px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_lavado_botellas:hover {
    transform: scale(1.05);
  }
  .text_lavado_botellas {
    position: absolute;
    left: 534px;
    top: 519px;
  }

  .boton_lavadora_cajas {
    position: absolute;
    left: 317px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_lavadora_cajas:hover {
    transform: scale(1.05);
  }
  .text_lavadora_cajas {
    position: absolute;
    left: 317px;
    top: 519px;
  }

  .boton_inspector_vacias {
    position: absolute;
    left: 751px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_inspector_vacias:hover {
    transform: scale(1.05);
  }
  .text_inspector_vacias {
    position: absolute;
    left: 751px;
    top: 519px;
  }

  .boton_llenadora {
    position: absolute;
    left: 972px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_llenadora:hover {
    transform: scale(1.05);
  }
  .text_llenadora {
    position: absolute;
    left: 972px;
    top: 519px;
  }

  .boton_inspector_llenas {
    position: absolute;
    left: 1189px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_inspector_llenas:hover {
    transform: scale(1.05);
  }
  .text_inspector_llenas {
    position: absolute;
    left: 1189px;
    top: 519px;
  }

  .boton_encajonadora {
    position: absolute;
    left: 1406px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_encajonadora:hover {
    transform: scale(1.05);
  }
  .text_encajonadora {
    position: absolute;
    left: 1406px;
    top: 519px;
  }

  .boton_paletizadora {
    position: absolute;
    left: 1624px;
    top: 519px;
    cursor: pointer;
    z-index: 6;
    transition: all 0.3s ease;
  }
  .boton_paletizadora:hover {
    transform: scale(1.05);
  }
  .text_paletizadora {
    position: absolute;
    left: 1624px;
    top: 519px;
  }

   .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
