<template>
  <div class="PG_3">
    <video
    preload="auto"
      data-autoplay
      loop
      src="https://desarrollo.factoriamedia.com/videos_tour/titulo2.mp4"
    ></video>
    <img
      src="@/assets/presentacion/PG_1/continuar.png"
      class="animated bounceIn slow continuar navigate-next"
      @click="$emit('event', 'Slide 3 - Continuar')"
      :style="{ display: slide.boton_continuar ? 'block' : 'none' }"
    />
        <img
      src="@/assets/presentacion/PG_1/atras.png"
      class="animated bounceIn slow volver navigate-left"
      :style="{ display: slide.boton_volver ? 'block' : 'none' }"
      @click="$emit('event', 'Slide 3 - Volver')"
    />
  </div>
  
</template>

<script>
import firebase from "firebase/app";
import "firebase/firestore";
var db = firebase.firestore();
export default {
  data() {
    return {
      slide: {},
    };
  },
  firestore: {
    slide: db.collection("presentacion").doc("PG_3"),
  },
};
</script>

<style lang="scss">
.PG_3 {
  .logo {
    position: absolute;
    left: 126px;
    top: 62px;
    width: 207px;
    height: 134px;
  }
  .logoD {
    position: absolute;
    left: 1642px;
    top: 76px;
    width: 193px;
    height: 68px;
  }
  .visita {
    position: absolute;
    left: 1640px;
    top: 75px;
  }
  .titulo {
    position: absolute;
    left: 150px;
    top: 75px;
  }
 .continuar {
    position: absolute;
    left: 998px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .continuar:hover {
    transform: scale(1.1);
  }
  .volver {
    position: absolute;
    left: 658px;
    top: 943px;
    cursor: pointer;
    transition: all 0.5s ease;
  }
  .volver:hover {
    transform: scale(1.1);
  }
}
</style>
