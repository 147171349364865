var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"PG_14"},[_c('img',{staticClass:"animated fadeInLeft logo",attrs:{"src":require("@/assets/presentacion/PG_2/logo.png")}}),_c('img',{staticClass:"animated fadeInRight visita",attrs:{"src":require("@/assets/presentacion/PG_2/visita.png")}}),_c('img',{staticClass:"animated fadeInUp titulo",attrs:{"src":require("@/assets/presentacion/PG_14/titulo.png")}}),_c('img',{staticClass:"animated fadeInDown titulo2",attrs:{"src":require("@/assets/presentacion/PG_14/titulo2.png")}}),_c('div',{staticClass:"temas animated bounceIn slow"},[_c('div',{staticClass:"item",class:_vm.selected.length > 0
          ? _vm.selected.includes('1')
            ? 'selected'
            : 'unselected'
          : '',attrs:{"id":"1"},on:{"click":function($event){_vm.toggle('1') & _vm.$emit('event', 'Slide 14 - Seleccionado Opción 1')}}},[_c('img',{attrs:{"src":require("@/assets/presentacion/PG_14/opt_1.png")}})]),_c('div',{staticClass:"item",class:_vm.selected.length > 0
          ? _vm.selected.includes('2')
            ? 'selected'
            : 'unselected'
          : '',attrs:{"id":"2"},on:{"click":function($event){_vm.toggle('2') & _vm.$emit('event', 'Slide 14 - Seleccionado Opción 2')}}},[_c('img',{attrs:{"src":require("@/assets/presentacion/PG_14/opt_2.png")}})]),_c('div',{staticClass:"item",class:_vm.selected.length > 0
          ? _vm.selected.includes('3')
            ? 'selected'
            : 'unselected'
          : '',attrs:{"id":"3"},on:{"click":function($event){_vm.toggle('3') & _vm.$emit('event', 'Slide 14 - Seleccionado Opción 3')}}},[_c('img',{attrs:{"src":require("@/assets/presentacion/PG_14/opt_3.png")}})]),_c('div',{staticClass:"item",class:_vm.selected.length > 0
          ? _vm.selected.includes('4')
            ? 'selected'
            : 'unselected'
          : '',attrs:{"id":"4"},on:{"click":function($event){_vm.toggle('4') & _vm.$emit('event', 'Slide 14 - Seleccionado Opción 4')}}},[_c('img',{attrs:{"src":require("@/assets/presentacion/PG_14/opt_4.png")}})]),_c('div',{staticClass:"item",class:_vm.selected.length > 0
          ? _vm.selected.includes('5')
            ? 'selected'
            : 'unselected'
          : '',attrs:{"id":"5"},on:{"click":function($event){_vm.toggle('5') & _vm.$emit('event', 'Slide 14 - Seleccionado Opción 5')}}},[_c('img',{attrs:{"src":require("@/assets/presentacion/PG_14/opt_5.png")}})]),_c('div',{staticClass:"item",class:_vm.selected.length > 0
          ? _vm.selected.includes('6')
            ? 'selected'
            : 'unselected'
          : '',attrs:{"id":"6"},on:{"click":function($event){_vm.toggle('6') & _vm.$emit('event', 'Slide 14 - Seleccionado Opción 6')}}},[_c('img',{attrs:{"src":require("@/assets/presentacion/PG_14/opt_6.png")}})]),_c('div',{staticClass:"item",class:_vm.selected.length > 0
          ? _vm.selected.includes('7')
            ? 'selected'
            : 'unselected'
          : '',attrs:{"id":"7"},on:{"click":function($event){_vm.toggle('7') & _vm.$emit('event', 'Slide 14 - Seleccionado Opción 7')}}},[_c('img',{attrs:{"src":require("@/assets/presentacion/PG_14/opt_7.png")}})])]),_c('img',{staticClass:"animated bounceIn slow continuar navigate-next",style:({ display: _vm.slide.boton_continuar ? 'block' : 'none' }),attrs:{"src":require("@/assets/presentacion/PG_1/continuar.png")},on:{"click":function($event){_vm.$emit('event', 'Slide 14 - Finalizar') && _vm.saveCuestionary()}}}),_c('img',{staticClass:"animated bounceIn slow volver navigate-left",style:({ display: _vm.slide.boton_volver ? 'block' : 'none' }),attrs:{"src":require("@/assets/presentacion/PG_1/atras.png")},on:{"click":function($event){return _vm.$emit('event', 'Slide 14 - Volver')}}})])}
var staticRenderFns = []

export { render, staticRenderFns }